<main [class.check-bg]="isShowBlur">
  <div class="bg-blur" *ngIf="isShowBlur"> </div>
  <section class="logo-inlive">
    <div class="inlive">
      <div class="inlive-logo">
        <img src="/assets/images/inlive.png" alt="">
      </div>
      <div class="inlive-text">
        <p>{{'TITLE' | translate}}</p>
      </div>
      <div class="inlive-list">
        <img src="/assets/images/inlive-with.png" alt="">
        <img src="/assets/images/inlive-stream.png" alt="">
        <img src="/assets/images/inlive-store.png" alt="">
        <img src="/assets/images/inlive-me.png" alt="">
        <img src="/assets/images/inlive-ticket.png" alt="">
      </div>
    </div>
    <div class="inlive-mobile">
      <div> <img src="../assets/images/icon.png" alt=""> </div>
      <div> <img src="../assets/images/splash.png" alt=""> </div>
    </div>
  </section>
  <section>
    <router-outlet></router-outlet>
  </section>
</main>
<app-loading></app-loading>
<app-pop-up></app-pop-up>
<p-toast></p-toast>