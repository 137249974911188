/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApplicationServicesListResponseModel } from '../models/application-services-list-response-model';
import { ApplicationServicesResponseModel } from '../models/application-services-response-model';
import { ApplicationUserClaimListResponseModel } from '../models/application-user-claim-list-response-model';
import { ApplicationUserClaimViewModel } from '../models/application-user-claim-view-model';
import { ApplicationUserPaginationModelResponseModel } from '../models/application-user-pagination-model-response-model';
import { ApplicationUserResponseModel } from '../models/application-user-response-model';
import { BooleanResponseModel } from '../models/boolean-response-model';
import { ChangePasswordViewModel } from '../models/change-password-view-model';
import { CheckAccountPasswordViewModel } from '../models/check-account-password-view-model';
import { CompleteExternalAuthModel } from '../models/complete-external-auth-model';
import { CompleteExternalAuthResultModelResponseModel } from '../models/complete-external-auth-result-model-response-model';
import { DateTimeNullableResponseModel } from '../models/date-time-nullable-response-model';
import { DeleteViewModel } from '../models/delete-view-model';
import { ForgetPasswordViewModel } from '../models/forget-password-view-model';
import { GuidListResponseModel } from '../models/guid-list-response-model';
import { LoginResultModelResponseModel } from '../models/login-result-model-response-model';
import { LoginViewModel } from '../models/login-view-model';
import { ProcessFileModelIdentity } from '../models/process-file-model-identity';
import { RegisterResultModelResponseModel } from '../models/register-result-model-response-model';
import { RegisterViewModel } from '../models/register-view-model';
import { ResetPasswordViewModel } from '../models/reset-password-view-model';
import { ResultSearchAccountViewModelForBoResponseModel } from '../models/result-search-account-view-model-for-bo-response-model';
import { ResultSearchAccountViewModelListResponseModel } from '../models/result-search-account-view-model-list-response-model';
import { ResultSearchAccountViewModelResponseModel } from '../models/result-search-account-view-model-response-model';
import { StringIListResponseModel } from '../models/string-i-list-response-model';
import { StringListResponseModel } from '../models/string-list-response-model';
import { StringResponseModel } from '../models/string-response-model';
import { UpdateModel } from '../models/update-model';
import { UserRoleViewModel } from '../models/user-role-view-model';
import { UploadAvatarResponseModelResponseModel } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation identityAccountUpdateIdPut
   */
  static readonly IdentityAccountUpdateIdPutPath = '/identity/Account/Update/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountUpdateIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountUpdateIdPut$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: UpdateModel
  }
): Observable<StrictHttpResponse<ApplicationUserResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountUpdateIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountUpdateIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountUpdateIdPut$Plain(params: {
    id: string;
    context?: HttpContext
    body?: UpdateModel
  }
): Observable<ApplicationUserResponseModel> {

    return this.identityAccountUpdateIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserResponseModel>) => r.body as ApplicationUserResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountUpdateIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountUpdateIdPut$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: UpdateModel
  }
): Observable<StrictHttpResponse<ApplicationUserResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountUpdateIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountUpdateIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountUpdateIdPut$Json(params: {
    id: string;
    context?: HttpContext
    body?: UpdateModel
  }
): Observable<ApplicationUserResponseModel> {

    return this.identityAccountUpdateIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserResponseModel>) => r.body as ApplicationUserResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountUpdateByAdministratorsIdPut
   */
  static readonly IdentityAccountUpdateByAdministratorsIdPutPath = '/identity/Account/UpdateByAdministrators/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountUpdateByAdministratorsIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountUpdateByAdministratorsIdPut$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: UpdateModel
  }
): Observable<StrictHttpResponse<ApplicationUserResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountUpdateByAdministratorsIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountUpdateByAdministratorsIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountUpdateByAdministratorsIdPut$Plain(params: {
    id: string;
    context?: HttpContext
    body?: UpdateModel
  }
): Observable<ApplicationUserResponseModel> {

    return this.identityAccountUpdateByAdministratorsIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserResponseModel>) => r.body as ApplicationUserResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountUpdateByAdministratorsIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountUpdateByAdministratorsIdPut$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: UpdateModel
  }
): Observable<StrictHttpResponse<ApplicationUserResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountUpdateByAdministratorsIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserResponseModel>;
      })
    );
  }

  
  /**
   * Path part for operation identityAccountChangeAvatarIdPut
   */
  static readonly IdentityAccountChangeAvatarIdPutPath = '/identity/Account/ChangeAvatar/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountChangeAvatarIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountChangeAvatarIdPut$Plain$Response(params: {
    id: string;
    context?: HttpContext
    fileName?: string;
  }): Observable<StrictHttpResponse<UploadAvatarResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountChangeAvatarIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('fileName', params.fileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UploadAvatarResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountChangeAvatarIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountChangeAvatarIdPut$Plain(params: {
    id: string;
    context?: HttpContext
    fileName?: string;
  }): Observable<UploadAvatarResponseModelResponseModel> {

    return this.identityAccountChangeAvatarIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UploadAvatarResponseModelResponseModel>) => r.body as UploadAvatarResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountChangeAvatarIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountChangeAvatarIdPut$Json$Response(params: {
    id: string;
    context?: HttpContext
    fileName?: string;
    
  }): Observable<StrictHttpResponse<UploadAvatarResponseModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountChangeAvatarIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('fileName', params.fileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UploadAvatarResponseModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountChangeAvatarIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountChangeAvatarIdPut$Json(params: {
    id: string;
    context?: HttpContext
    fileName?: string;
  }): Observable<UploadAvatarResponseModelResponseModel> {

    return this.identityAccountChangeAvatarIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UploadAvatarResponseModelResponseModel>) => r.body as UploadAvatarResponseModelResponseModel)
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountUpdateByAdministratorsIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountUpdateByAdministratorsIdPut$Json(params: {
    id: string;
    context?: HttpContext
    body?: UpdateModel
  }
): Observable<ApplicationUserResponseModel> {

    return this.identityAccountUpdateByAdministratorsIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserResponseModel>) => r.body as ApplicationUserResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountUploadAvatarIdPut
   */
  static readonly IdentityAccountUploadAvatarIdPutPath = '/identity/Account/UploadAvatar/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountUploadAvatarIdPut$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  identityAccountUploadAvatarIdPut$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: {
'file'?: Blob;
}
  }
): Observable<StrictHttpResponse<ApplicationUserResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountUploadAvatarIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountUploadAvatarIdPut$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  identityAccountUploadAvatarIdPut$Plain(params: {
    id: string;
    context?: HttpContext
    body?: {
'file'?: Blob;
}
  }
): Observable<ApplicationUserResponseModel> {

    return this.identityAccountUploadAvatarIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserResponseModel>) => r.body as ApplicationUserResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountUploadAvatarIdPut$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  identityAccountUploadAvatarIdPut$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: {
'file'?: Blob;
}
  }
): Observable<StrictHttpResponse<ApplicationUserResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountUploadAvatarIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountUploadAvatarIdPut$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  identityAccountUploadAvatarIdPut$Json(params: {
    id: string;
    context?: HttpContext
    body?: {
'file'?: Blob;
}
  }
): Observable<ApplicationUserResponseModel> {

    return this.identityAccountUploadAvatarIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserResponseModel>) => r.body as ApplicationUserResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountUpdateProcessAvatarIdPut
   */
  static readonly IdentityAccountUpdateProcessAvatarIdPutPath = '/identity/Account/UpdateProcessAvatar/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountUpdateProcessAvatarIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountUpdateProcessAvatarIdPut$Plain$Response(params: {
    id: string;
    isProcessing?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountUpdateProcessAvatarIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('isProcessing', params.isProcessing, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountUpdateProcessAvatarIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountUpdateProcessAvatarIdPut$Plain(params: {
    id: string;
    isProcessing?: boolean;
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountUpdateProcessAvatarIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountUpdateProcessAvatarIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountUpdateProcessAvatarIdPut$Json$Response(params: {
    id: string;
    isProcessing?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountUpdateProcessAvatarIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('isProcessing', params.isProcessing, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountUpdateProcessAvatarIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountUpdateProcessAvatarIdPut$Json(params: {
    id: string;
    isProcessing?: boolean;
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountUpdateProcessAvatarIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountProcessWhenUploadAvatarIdPut
   */
  static readonly IdentityAccountProcessWhenUploadAvatarIdPutPath = '/identity/Account/ProcessWhenUploadAvatar/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountProcessWhenUploadAvatarIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountProcessWhenUploadAvatarIdPut$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: ProcessFileModelIdentity
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountProcessWhenUploadAvatarIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountProcessWhenUploadAvatarIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountProcessWhenUploadAvatarIdPut$Plain(params: {
    id: string;
    context?: HttpContext
    body?: ProcessFileModelIdentity
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountProcessWhenUploadAvatarIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountProcessWhenUploadAvatarIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountProcessWhenUploadAvatarIdPut$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: ProcessFileModelIdentity
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountProcessWhenUploadAvatarIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountProcessWhenUploadAvatarIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountProcessWhenUploadAvatarIdPut$Json(params: {
    id: string;
    context?: HttpContext
    body?: ProcessFileModelIdentity
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountProcessWhenUploadAvatarIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountSearchLikeEmailSearchGet
   */
  static readonly IdentityAccountSearchLikeEmailSearchGetPath = '/identity/Account/SearchLikeEmail/{search}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSearchLikeEmailSearchGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchLikeEmailSearchGet$Plain$Response(params: {
    search: string;
    page?: number;
    limit?: number;
    detail?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ApplicationUserPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSearchLikeEmailSearchGetPath, 'get');
    if (params) {
      rb.path('search', params.search, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('detail', params.detail, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSearchLikeEmailSearchGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchLikeEmailSearchGet$Plain(params: {
    search: string;
    page?: number;
    limit?: number;
    detail?: boolean;
    context?: HttpContext
  }
): Observable<ApplicationUserPaginationModelResponseModel> {

    return this.identityAccountSearchLikeEmailSearchGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserPaginationModelResponseModel>) => r.body as ApplicationUserPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSearchLikeEmailSearchGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchLikeEmailSearchGet$Json$Response(params: {
    search: string;
    page?: number;
    limit?: number;
    detail?: boolean;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ApplicationUserPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSearchLikeEmailSearchGetPath, 'get');
    if (params) {
      rb.path('search', params.search, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('detail', params.detail, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSearchLikeEmailSearchGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchLikeEmailSearchGet$Json(params: {
    search: string;
    page?: number;
    limit?: number;
    detail?: boolean;
    context?: HttpContext
  }
): Observable<ApplicationUserPaginationModelResponseModel> {

    return this.identityAccountSearchLikeEmailSearchGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserPaginationModelResponseModel>) => r.body as ApplicationUserPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountRegisterPost
   */
  static readonly IdentityAccountRegisterPostPath = '/identity/Account/Register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountRegisterPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountRegisterPost$Plain$Response(params?: {
    context?: HttpContext
    body?: RegisterViewModel
  }
): Observable<StrictHttpResponse<RegisterResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegisterResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountRegisterPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountRegisterPost$Plain(params?: {
    context?: HttpContext
    body?: RegisterViewModel
  }
): Observable<RegisterResultModelResponseModel> {

    return this.identityAccountRegisterPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RegisterResultModelResponseModel>) => r.body as RegisterResultModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountRegisterPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountRegisterPost$Json$Response(params?: {
    context?: HttpContext
    body?: RegisterViewModel
  }
): Observable<StrictHttpResponse<RegisterResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegisterResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountRegisterPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountRegisterPost$Json(params?: {
    context?: HttpContext
    body?: RegisterViewModel
  }
): Observable<RegisterResultModelResponseModel> {

    return this.identityAccountRegisterPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RegisterResultModelResponseModel>) => r.body as RegisterResultModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountGetUserClaimAccountIdGet
   */
  static readonly IdentityAccountGetUserClaimAccountIdGetPath = '/identity/Account/GetUserClaim/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountGetUserClaimAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetUserClaimAccountIdGet$Plain$Response(params: {
    accountId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ApplicationUserClaimListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountGetUserClaimAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserClaimListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountGetUserClaimAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetUserClaimAccountIdGet$Plain(params: {
    accountId: string;
    context?: HttpContext
  }
): Observable<ApplicationUserClaimListResponseModel> {

    return this.identityAccountGetUserClaimAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserClaimListResponseModel>) => r.body as ApplicationUserClaimListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountGetUserClaimAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetUserClaimAccountIdGet$Json$Response(params: {
    accountId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ApplicationUserClaimListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountGetUserClaimAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserClaimListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountGetUserClaimAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetUserClaimAccountIdGet$Json(params: {
    accountId: string;
    context?: HttpContext
  }
): Observable<ApplicationUserClaimListResponseModel> {

    return this.identityAccountGetUserClaimAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserClaimListResponseModel>) => r.body as ApplicationUserClaimListResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountAddUserClaimAccountIdPost
   */
  static readonly IdentityAccountAddUserClaimAccountIdPostPath = '/identity/Account/AddUserClaim/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAddUserClaimAccountIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAddUserClaimAccountIdPost$Plain$Response(params: {
    accountId: string;
    context?: HttpContext
    body?: Array<ApplicationUserClaimViewModel>
  }
): Observable<StrictHttpResponse<ApplicationUserClaimListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountAddUserClaimAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserClaimListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAddUserClaimAccountIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAddUserClaimAccountIdPost$Plain(params: {
    accountId: string;
    context?: HttpContext
    body?: Array<ApplicationUserClaimViewModel>
  }
): Observable<ApplicationUserClaimListResponseModel> {

    return this.identityAccountAddUserClaimAccountIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserClaimListResponseModel>) => r.body as ApplicationUserClaimListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAddUserClaimAccountIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAddUserClaimAccountIdPost$Json$Response(params: {
    accountId: string;
    context?: HttpContext
    body?: Array<ApplicationUserClaimViewModel>
  }
): Observable<StrictHttpResponse<ApplicationUserClaimListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountAddUserClaimAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserClaimListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAddUserClaimAccountIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAddUserClaimAccountIdPost$Json(params: {
    accountId: string;
    context?: HttpContext
    body?: Array<ApplicationUserClaimViewModel>
  }
): Observable<ApplicationUserClaimListResponseModel> {

    return this.identityAccountAddUserClaimAccountIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserClaimListResponseModel>) => r.body as ApplicationUserClaimListResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountUpdateUserClaimAccountIdPut
   */
  static readonly IdentityAccountUpdateUserClaimAccountIdPutPath = '/identity/Account/UpdateUserClaim/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountUpdateUserClaimAccountIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountUpdateUserClaimAccountIdPut$Plain$Response(params: {
    accountId: string;
    context?: HttpContext
    body?: Array<ApplicationUserClaimViewModel>
  }
): Observable<StrictHttpResponse<ApplicationUserClaimListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountUpdateUserClaimAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserClaimListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountUpdateUserClaimAccountIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountUpdateUserClaimAccountIdPut$Plain(params: {
    accountId: string;
    context?: HttpContext
    body?: Array<ApplicationUserClaimViewModel>
  }
): Observable<ApplicationUserClaimListResponseModel> {

    return this.identityAccountUpdateUserClaimAccountIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserClaimListResponseModel>) => r.body as ApplicationUserClaimListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountUpdateUserClaimAccountIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountUpdateUserClaimAccountIdPut$Json$Response(params: {
    accountId: string;
    context?: HttpContext
    body?: Array<ApplicationUserClaimViewModel>
  }
): Observable<StrictHttpResponse<ApplicationUserClaimListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountUpdateUserClaimAccountIdPutPath, 'put');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserClaimListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountUpdateUserClaimAccountIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountUpdateUserClaimAccountIdPut$Json(params: {
    accountId: string;
    context?: HttpContext
    body?: Array<ApplicationUserClaimViewModel>
  }
): Observable<ApplicationUserClaimListResponseModel> {

    return this.identityAccountUpdateUserClaimAccountIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserClaimListResponseModel>) => r.body as ApplicationUserClaimListResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountRemoveUserClaimAccountIdPost
   */
  static readonly IdentityAccountRemoveUserClaimAccountIdPostPath = '/identity/Account/RemoveUserClaim/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountRemoveUserClaimAccountIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountRemoveUserClaimAccountIdPost$Plain$Response(params: {
    accountId: string;
    context?: HttpContext
    body?: Array<ApplicationUserClaimViewModel>
  }
): Observable<StrictHttpResponse<ApplicationUserClaimListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountRemoveUserClaimAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserClaimListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountRemoveUserClaimAccountIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountRemoveUserClaimAccountIdPost$Plain(params: {
    accountId: string;
    context?: HttpContext
    body?: Array<ApplicationUserClaimViewModel>
  }
): Observable<ApplicationUserClaimListResponseModel> {

    return this.identityAccountRemoveUserClaimAccountIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserClaimListResponseModel>) => r.body as ApplicationUserClaimListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountRemoveUserClaimAccountIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountRemoveUserClaimAccountIdPost$Json$Response(params: {
    accountId: string;
    context?: HttpContext
    body?: Array<ApplicationUserClaimViewModel>
  }
): Observable<StrictHttpResponse<ApplicationUserClaimListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountRemoveUserClaimAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserClaimListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountRemoveUserClaimAccountIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountRemoveUserClaimAccountIdPost$Json(params: {
    accountId: string;
    context?: HttpContext
    body?: Array<ApplicationUserClaimViewModel>
  }
): Observable<ApplicationUserClaimListResponseModel> {

    return this.identityAccountRemoveUserClaimAccountIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserClaimListResponseModel>) => r.body as ApplicationUserClaimListResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountReSendConfirmEmailEmailPost
   */
  static readonly IdentityAccountReSendConfirmEmailEmailPostPath = '/identity/Account/ReSendConfirmEmail/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountReSendConfirmEmailEmailPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountReSendConfirmEmailEmailPost$Plain$Response(params: {
    email: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountReSendConfirmEmailEmailPostPath, 'post');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountReSendConfirmEmailEmailPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountReSendConfirmEmailEmailPost$Plain(params: {
    email: string;
    context?: HttpContext
  }
): Observable<StringResponseModel> {

    return this.identityAccountReSendConfirmEmailEmailPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountReSendConfirmEmailEmailPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountReSendConfirmEmailEmailPost$Json$Response(params: {
    email: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountReSendConfirmEmailEmailPostPath, 'post');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountReSendConfirmEmailEmailPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountReSendConfirmEmailEmailPost$Json(params: {
    email: string;
    context?: HttpContext
  }
): Observable<StringResponseModel> {

    return this.identityAccountReSendConfirmEmailEmailPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountSendConfirmationEmailCultureEmailNicknamePost
   */
  static readonly IdentityAccountSendConfirmationEmailCultureEmailNicknamePostPath = '/identity/Account/SendConfirmationEmail/{culture}/{email}/{nickname}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSendConfirmationEmailCultureEmailNicknamePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountSendConfirmationEmailCultureEmailNicknamePost$Plain$Response(params: {
    culture: string;
    email: string;
    nickname: string;
    context?: HttpContext
    body?: string
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSendConfirmationEmailCultureEmailNicknamePostPath, 'post');
    if (params) {
      rb.path('culture', params.culture, {});
      rb.path('email', params.email, {});
      rb.path('nickname', params.nickname, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSendConfirmationEmailCultureEmailNicknamePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountSendConfirmationEmailCultureEmailNicknamePost$Plain(params: {
    culture: string;
    email: string;
    nickname: string;
    context?: HttpContext
    body?: string
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountSendConfirmationEmailCultureEmailNicknamePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSendConfirmationEmailCultureEmailNicknamePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountSendConfirmationEmailCultureEmailNicknamePost$Json$Response(params: {
    culture: string;
    email: string;
    nickname: string;
    context?: HttpContext
    body?: string
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSendConfirmationEmailCultureEmailNicknamePostPath, 'post');
    if (params) {
      rb.path('culture', params.culture, {});
      rb.path('email', params.email, {});
      rb.path('nickname', params.nickname, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSendConfirmationEmailCultureEmailNicknamePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountSendConfirmationEmailCultureEmailNicknamePost$Json(params: {
    culture: string;
    email: string;
    nickname: string;
    context?: HttpContext
    body?: string
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountSendConfirmationEmailCultureEmailNicknamePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountSendConfirmationDeletedCultureEmailNicknamePost
   */
  static readonly IdentityAccountSendConfirmationDeletedCultureEmailNicknamePostPath = '/identity/Account/SendConfirmationDeleted/{culture}/{email}/{nickname}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSendConfirmationDeletedCultureEmailNicknamePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountSendConfirmationDeletedCultureEmailNicknamePost$Plain$Response(params: {
    culture: string;
    email: string;
    nickname: string;
    context?: HttpContext
    body?: string
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSendConfirmationDeletedCultureEmailNicknamePostPath, 'post');
    if (params) {
      rb.path('culture', params.culture, {});
      rb.path('email', params.email, {});
      rb.path('nickname', params.nickname, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSendConfirmationDeletedCultureEmailNicknamePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountSendConfirmationDeletedCultureEmailNicknamePost$Plain(params: {
    culture: string;
    email: string;
    nickname: string;
    context?: HttpContext
    body?: string
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountSendConfirmationDeletedCultureEmailNicknamePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSendConfirmationDeletedCultureEmailNicknamePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountSendConfirmationDeletedCultureEmailNicknamePost$Json$Response(params: {
    culture: string;
    email: string;
    nickname: string;
    context?: HttpContext
    body?: string
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSendConfirmationDeletedCultureEmailNicknamePostPath, 'post');
    if (params) {
      rb.path('culture', params.culture, {});
      rb.path('email', params.email, {});
      rb.path('nickname', params.nickname, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSendConfirmationDeletedCultureEmailNicknamePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountSendConfirmationDeletedCultureEmailNicknamePost$Json(params: {
    culture: string;
    email: string;
    nickname: string;
    context?: HttpContext
    body?: string
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountSendConfirmationDeletedCultureEmailNicknamePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountSendResetPasswordEmailCultureEmailPost
   */
  static readonly IdentityAccountSendResetPasswordEmailCultureEmailPostPath = '/identity/Account/SendResetPasswordEmail/{culture}/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSendResetPasswordEmailCultureEmailPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountSendResetPasswordEmailCultureEmailPost$Plain$Response(params: {
    culture: string;
    email: string;
    context?: HttpContext
    body?: string
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSendResetPasswordEmailCultureEmailPostPath, 'post');
    if (params) {
      rb.path('culture', params.culture, {});
      rb.path('email', params.email, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSendResetPasswordEmailCultureEmailPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountSendResetPasswordEmailCultureEmailPost$Plain(params: {
    culture: string;
    email: string;
    context?: HttpContext
    body?: string
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountSendResetPasswordEmailCultureEmailPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSendResetPasswordEmailCultureEmailPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountSendResetPasswordEmailCultureEmailPost$Json$Response(params: {
    culture: string;
    email: string;
    context?: HttpContext
    body?: string
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSendResetPasswordEmailCultureEmailPostPath, 'post');
    if (params) {
      rb.path('culture', params.culture, {});
      rb.path('email', params.email, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSendResetPasswordEmailCultureEmailPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountSendResetPasswordEmailCultureEmailPost$Json(params: {
    culture: string;
    email: string;
    context?: HttpContext
    body?: string
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountSendResetPasswordEmailCultureEmailPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountLoginPost
   */
  static readonly IdentityAccountLoginPostPath = '/identity/Account/Login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountLoginPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountLoginPost$Plain$Response(params?: {
    context?: HttpContext
    body?: LoginViewModel
  }
): Observable<StrictHttpResponse<LoginResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountLoginPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountLoginPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountLoginPost$Plain(params?: {
    context?: HttpContext
    body?: LoginViewModel
  }
): Observable<LoginResultModelResponseModel> {

    return this.identityAccountLoginPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LoginResultModelResponseModel>) => r.body as LoginResultModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountLoginPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountLoginPost$Json$Response(params?: {
    context?: HttpContext
    body?: LoginViewModel
  }
): Observable<StrictHttpResponse<LoginResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountLoginPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountLoginPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountLoginPost$Json(params?: {
    context?: HttpContext
    body?: LoginViewModel
  }
): Observable<LoginResultModelResponseModel> {

    return this.identityAccountLoginPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LoginResultModelResponseModel>) => r.body as LoginResultModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountCompleteExternalLoginAccountIdPost
   */
  static readonly IdentityAccountCompleteExternalLoginAccountIdPostPath = '/identity/Account/CompleteExternalLogin/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountCompleteExternalLoginAccountIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountCompleteExternalLoginAccountIdPost$Plain$Response(params: {
    accountId: string;
    context?: HttpContext
    body?: CompleteExternalAuthModel
  }
): Observable<StrictHttpResponse<CompleteExternalAuthResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountCompleteExternalLoginAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompleteExternalAuthResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountCompleteExternalLoginAccountIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountCompleteExternalLoginAccountIdPost$Plain(params: {
    accountId: string;
    context?: HttpContext
    body?: CompleteExternalAuthModel
  }
): Observable<CompleteExternalAuthResultModelResponseModel> {

    return this.identityAccountCompleteExternalLoginAccountIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CompleteExternalAuthResultModelResponseModel>) => r.body as CompleteExternalAuthResultModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountCompleteExternalLoginAccountIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountCompleteExternalLoginAccountIdPost$Json$Response(params: {
    accountId: string;
    context?: HttpContext
    body?: CompleteExternalAuthModel
  }
): Observable<StrictHttpResponse<CompleteExternalAuthResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountCompleteExternalLoginAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompleteExternalAuthResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountCompleteExternalLoginAccountIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountCompleteExternalLoginAccountIdPost$Json(params: {
    accountId: string;
    context?: HttpContext
    body?: CompleteExternalAuthModel
  }
): Observable<CompleteExternalAuthResultModelResponseModel> {

    return this.identityAccountCompleteExternalLoginAccountIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CompleteExternalAuthResultModelResponseModel>) => r.body as CompleteExternalAuthResultModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountConfirmEmailGet
   */
  static readonly IdentityAccountConfirmEmailGetPath = '/identity/Account/ConfirmEmail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountConfirmEmailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountConfirmEmailGet$Plain$Response(params?: {
    email?: string;
    token?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountConfirmEmailGetPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountConfirmEmailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountConfirmEmailGet$Plain(params?: {
    email?: string;
    token?: string;
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountConfirmEmailGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountConfirmEmailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountConfirmEmailGet$Json$Response(params?: {
    email?: string;
    token?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountConfirmEmailGetPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountConfirmEmailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountConfirmEmailGet$Json(params?: {
    email?: string;
    token?: string;
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountConfirmEmailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountValidateAccountAccountIdPost
   */
  static readonly IdentityAccountValidateAccountAccountIdPostPath = '/identity/Account/ValidateAccount/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountValidateAccountAccountIdPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountValidateAccountAccountIdPost$Plain$Response(params: {
    accountId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountValidateAccountAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountValidateAccountAccountIdPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountValidateAccountAccountIdPost$Plain(params: {
    accountId: string;
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountValidateAccountAccountIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountValidateAccountAccountIdPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountValidateAccountAccountIdPost$Json$Response(params: {
    accountId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountValidateAccountAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountValidateAccountAccountIdPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountValidateAccountAccountIdPost$Json(params: {
    accountId: string;
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountValidateAccountAccountIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountForgotPasswordPost
   */
  static readonly IdentityAccountForgotPasswordPostPath = '/identity/Account/ForgotPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountForgotPasswordPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountForgotPasswordPost$Plain$Response(params?: {
    context?: HttpContext
    body?: ForgetPasswordViewModel
  }
): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountForgotPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountForgotPasswordPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountForgotPasswordPost$Plain(params?: {
    context?: HttpContext
    body?: ForgetPasswordViewModel
  }
): Observable<StringResponseModel> {

    return this.identityAccountForgotPasswordPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountForgotPasswordPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountForgotPasswordPost$Json$Response(params?: {
    context?: HttpContext
    body?: ForgetPasswordViewModel
  }
): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountForgotPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountForgotPasswordPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountForgotPasswordPost$Json(params?: {
    context?: HttpContext
    body?: ForgetPasswordViewModel
  }
): Observable<StringResponseModel> {

    return this.identityAccountForgotPasswordPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountResetPasswordPost
   */
  static readonly IdentityAccountResetPasswordPostPath = '/identity/Account/ResetPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountResetPasswordPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountResetPasswordPost$Plain$Response(params?: {
    context?: HttpContext
    body?: ResetPasswordViewModel
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountResetPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountResetPasswordPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountResetPasswordPost$Plain(params?: {
    context?: HttpContext
    body?: ResetPasswordViewModel
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountResetPasswordPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountResetPasswordPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountResetPasswordPost$Json$Response(params?: {
    context?: HttpContext
    body?: ResetPasswordViewModel
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountResetPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountResetPasswordPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountResetPasswordPost$Json(params?: {
    context?: HttpContext
    body?: ResetPasswordViewModel
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountResetPasswordPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountChangePasswordPost
   */
  static readonly IdentityAccountChangePasswordPostPath = '/identity/Account/ChangePassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountChangePasswordPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountChangePasswordPost$Plain$Response(params?: {
    context?: HttpContext
    body?: ChangePasswordViewModel
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountChangePasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountChangePasswordPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountChangePasswordPost$Plain(params?: {
    context?: HttpContext
    body?: ChangePasswordViewModel
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountChangePasswordPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountChangePasswordPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountChangePasswordPost$Json$Response(params?: {
    context?: HttpContext
    body?: ChangePasswordViewModel
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountChangePasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountChangePasswordPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountChangePasswordPost$Json(params?: {
    context?: HttpContext
    body?: ChangePasswordViewModel
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountChangePasswordPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountAddToRolePost
   */
  static readonly IdentityAccountAddToRolePostPath = '/identity/Account/AddToRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAddToRolePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAddToRolePost$Plain$Response(params?: {
    context?: HttpContext
    body?: UserRoleViewModel
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountAddToRolePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAddToRolePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAddToRolePost$Plain(params?: {
    context?: HttpContext
    body?: UserRoleViewModel
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountAddToRolePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAddToRolePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAddToRolePost$Json$Response(params?: {
    context?: HttpContext
    body?: UserRoleViewModel
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountAddToRolePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAddToRolePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAddToRolePost$Json(params?: {
    context?: HttpContext
    body?: UserRoleViewModel
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountAddToRolePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountRemoveFromRolePost
   */
  static readonly IdentityAccountRemoveFromRolePostPath = '/identity/Account/RemoveFromRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountRemoveFromRolePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountRemoveFromRolePost$Plain$Response(params?: {
    context?: HttpContext
    body?: UserRoleViewModel
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountRemoveFromRolePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountRemoveFromRolePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountRemoveFromRolePost$Plain(params?: {
    context?: HttpContext
    body?: UserRoleViewModel
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountRemoveFromRolePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountRemoveFromRolePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountRemoveFromRolePost$Json$Response(params?: {
    context?: HttpContext
    body?: UserRoleViewModel
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountRemoveFromRolePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountRemoveFromRolePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountRemoveFromRolePost$Json(params?: {
    context?: HttpContext
    body?: UserRoleViewModel
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountRemoveFromRolePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountGetUserRolesEmailGet
   */
  static readonly IdentityAccountGetUserRolesEmailGetPath = '/identity/Account/GetUserRoles/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountGetUserRolesEmailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetUserRolesEmailGet$Plain$Response(params: {
    email: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StringIListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountGetUserRolesEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringIListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountGetUserRolesEmailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetUserRolesEmailGet$Plain(params: {
    email: string;
    context?: HttpContext
  }
): Observable<StringIListResponseModel> {

    return this.identityAccountGetUserRolesEmailGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringIListResponseModel>) => r.body as StringIListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountGetUserRolesEmailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetUserRolesEmailGet$Json$Response(params: {
    email: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StringIListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountGetUserRolesEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringIListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountGetUserRolesEmailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetUserRolesEmailGet$Json(params: {
    email: string;
    context?: HttpContext
  }
): Observable<StringIListResponseModel> {

    return this.identityAccountGetUserRolesEmailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringIListResponseModel>) => r.body as StringIListResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountRefreshTokenPost
   */
  static readonly IdentityAccountRefreshTokenPostPath = '/identity/Account/RefreshToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountRefreshTokenPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountRefreshTokenPost$Plain$Response(params?: {
    token?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountRefreshTokenPostPath, 'post');
    if (params) {
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountRefreshTokenPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountRefreshTokenPost$Plain(params?: {
    token?: string;
    context?: HttpContext
  }
): Observable<StringResponseModel> {

    return this.identityAccountRefreshTokenPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountRefreshTokenPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountRefreshTokenPost$Json$Response(params?: {
    token?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountRefreshTokenPostPath, 'post');
    if (params) {
      rb.query('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountRefreshTokenPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountRefreshTokenPost$Json(params?: {
    token?: string;
    context?: HttpContext
  }
): Observable<StringResponseModel> {

    return this.identityAccountRefreshTokenPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountDeleteByIdDelete
   */
  static readonly IdentityAccountDeleteByIdDeletePath = '/identity/Account/DeleteById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountDeleteByIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountDeleteByIdDelete$Plain$Response(params?: {
    id?: string;
    context?: HttpContext
    body?: DeleteViewModel
  }
): Observable<StrictHttpResponse<ApplicationUserResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountDeleteByIdDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountDeleteByIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountDeleteByIdDelete$Plain(params?: {
    id?: string;
    context?: HttpContext
    body?: DeleteViewModel
  }
): Observable<ApplicationUserResponseModel> {

    return this.identityAccountDeleteByIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserResponseModel>) => r.body as ApplicationUserResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountDeleteByIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountDeleteByIdDelete$Json$Response(params?: {
    id?: string;
    context?: HttpContext
    body?: DeleteViewModel
  }
): Observable<StrictHttpResponse<ApplicationUserResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountDeleteByIdDeletePath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountDeleteByIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountDeleteByIdDelete$Json(params?: {
    id?: string;
    context?: HttpContext
    body?: DeleteViewModel
  }
): Observable<ApplicationUserResponseModel> {

    return this.identityAccountDeleteByIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserResponseModel>) => r.body as ApplicationUserResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountDeleteByIdsDelete
   */
  static readonly IdentityAccountDeleteByIdsDeletePath = '/identity/Account/DeleteByIds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountDeleteByIdsDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountDeleteByIdsDelete$Plain$Response(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountDeleteByIdsDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountDeleteByIdsDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountDeleteByIdsDelete$Plain(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountDeleteByIdsDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountDeleteByIdsDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountDeleteByIdsDelete$Json$Response(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountDeleteByIdsDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountDeleteByIdsDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountDeleteByIdsDelete$Json(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountDeleteByIdsDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountDeleteByEmailsDelete
   */
  static readonly IdentityAccountDeleteByEmailsDeletePath = '/identity/Account/DeleteByEmails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountDeleteByEmailsDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountDeleteByEmailsDelete$Plain$Response(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountDeleteByEmailsDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountDeleteByEmailsDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountDeleteByEmailsDelete$Plain(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountDeleteByEmailsDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountDeleteByEmailsDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountDeleteByEmailsDelete$Json$Response(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountDeleteByEmailsDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountDeleteByEmailsDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountDeleteByEmailsDelete$Json(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountDeleteByEmailsDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountSearchByUsernameUserNameGet
   */
  static readonly IdentityAccountSearchByUsernameUserNameGetPath = '/identity/Account/SearchByUsername/{userName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSearchByUsernameUserNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByUsernameUserNameGet$Plain$Response(params: {
    userName: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSearchByUsernameUserNameGetPath, 'get');
    if (params) {
      rb.path('userName', params.userName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSearchByUsernameUserNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByUsernameUserNameGet$Plain(params: {
    userName: string;
    context?: HttpContext
  }
): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.identityAccountSearchByUsernameUserNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSearchByUsernameUserNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByUsernameUserNameGet$Json$Response(params: {
    userName: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSearchByUsernameUserNameGetPath, 'get');
    if (params) {
      rb.path('userName', params.userName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSearchByUsernameUserNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByUsernameUserNameGet$Json(params: {
    userName: string;
    context?: HttpContext
  }
): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.identityAccountSearchByUsernameUserNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountMeGet
   */
  static readonly IdentityAccountMeGetPath = '/identity/Account/Me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountMeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountMeGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountMeGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountMeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountMeGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.identityAccountMeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountMeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountMeGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountMeGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountMeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountMeGet$Json(params?: {
    context?: HttpContext
  }
): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.identityAccountMeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountSearchByIdAccountIdGet
   */
  static readonly IdentityAccountSearchByIdAccountIdGetPath = '/identity/Account/SearchById/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSearchByIdAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByIdAccountIdGet$Plain$Response(params: {
    accountId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSearchByIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSearchByIdAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByIdAccountIdGet$Plain(params: {
    accountId: string;
    context?: HttpContext
  }
): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.identityAccountSearchByIdAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSearchByIdAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByIdAccountIdGet$Json$Response(params: {
    accountId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSearchByIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSearchByIdAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByIdAccountIdGet$Json(params: {
    accountId: string;
    context?: HttpContext
  }
): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.identityAccountSearchByIdAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountSearchAccountByDisplayNameDisplayNameGet
   */
  static readonly IdentityAccountSearchAccountByDisplayNameDisplayNameGetPath = '/identity/Account/SearchAccountByDisplayName/{displayName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSearchAccountByDisplayNameDisplayNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchAccountByDisplayNameDisplayNameGet$Plain$Response(params: {
    displayName: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSearchAccountByDisplayNameDisplayNameGetPath, 'get');
    if (params) {
      rb.path('displayName', params.displayName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSearchAccountByDisplayNameDisplayNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchAccountByDisplayNameDisplayNameGet$Plain(params: {
    displayName: string;
    context?: HttpContext
  }
): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.identityAccountSearchAccountByDisplayNameDisplayNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSearchAccountByDisplayNameDisplayNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchAccountByDisplayNameDisplayNameGet$Json$Response(params: {
    displayName: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSearchAccountByDisplayNameDisplayNameGetPath, 'get');
    if (params) {
      rb.path('displayName', params.displayName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSearchAccountByDisplayNameDisplayNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchAccountByDisplayNameDisplayNameGet$Json(params: {
    displayName: string;
    context?: HttpContext
  }
): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.identityAccountSearchAccountByDisplayNameDisplayNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountSearchAccountByIdsPost
   */
  static readonly IdentityAccountSearchAccountByIdsPostPath = '/identity/Account/SearchAccountByIds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSearchAccountByIdsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountSearchAccountByIdsPost$Plain$Response(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<ResultSearchAccountViewModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSearchAccountByIdsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSearchAccountByIdsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountSearchAccountByIdsPost$Plain(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<ResultSearchAccountViewModelListResponseModel> {

    return this.identityAccountSearchAccountByIdsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelListResponseModel>) => r.body as ResultSearchAccountViewModelListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSearchAccountByIdsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountSearchAccountByIdsPost$Json$Response(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<StrictHttpResponse<ResultSearchAccountViewModelListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSearchAccountByIdsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSearchAccountByIdsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountSearchAccountByIdsPost$Json(params?: {
    context?: HttpContext
    body?: Array<string>
  }
): Observable<ResultSearchAccountViewModelListResponseModel> {

    return this.identityAccountSearchAccountByIdsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelListResponseModel>) => r.body as ResultSearchAccountViewModelListResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountSearchByEmailEmailGet
   */
  static readonly IdentityAccountSearchByEmailEmailGetPath = '/identity/Account/SearchByEmail/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSearchByEmailEmailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByEmailEmailGet$Plain$Response(params: {
    email: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSearchByEmailEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSearchByEmailEmailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByEmailEmailGet$Plain(params: {
    email: string;
    context?: HttpContext
  }
): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.identityAccountSearchByEmailEmailGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSearchByEmailEmailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByEmailEmailGet$Json$Response(params: {
    email: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSearchByEmailEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSearchByEmailEmailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchByEmailEmailGet$Json(params: {
    email: string;
    context?: HttpContext
  }
): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.identityAccountSearchByEmailEmailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountSearchAccountByEmailForBackOfficeEmailGet
   */
  static readonly IdentityAccountSearchAccountByEmailForBackOfficeEmailGetPath = '/identity/Account/SearchAccountByEmailForBackOffice/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSearchAccountByEmailForBackOfficeEmailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchAccountByEmailForBackOfficeEmailGet$Plain$Response(params: {
    email: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ResultSearchAccountViewModelForBoResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSearchAccountByEmailForBackOfficeEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelForBoResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSearchAccountByEmailForBackOfficeEmailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchAccountByEmailForBackOfficeEmailGet$Plain(params: {
    email: string;
    context?: HttpContext
  }
): Observable<ResultSearchAccountViewModelForBoResponseModel> {

    return this.identityAccountSearchAccountByEmailForBackOfficeEmailGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelForBoResponseModel>) => r.body as ResultSearchAccountViewModelForBoResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountSearchAccountByEmailForBackOfficeEmailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchAccountByEmailForBackOfficeEmailGet$Json$Response(params: {
    email: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ResultSearchAccountViewModelForBoResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountSearchAccountByEmailForBackOfficeEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelForBoResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountSearchAccountByEmailForBackOfficeEmailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountSearchAccountByEmailForBackOfficeEmailGet$Json(params: {
    email: string;
    context?: HttpContext
  }
): Observable<ResultSearchAccountViewModelForBoResponseModel> {

    return this.identityAccountSearchAccountByEmailForBackOfficeEmailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelForBoResponseModel>) => r.body as ResultSearchAccountViewModelForBoResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountForceChangePasswordPost
   */
  static readonly IdentityAccountForceChangePasswordPostPath = '/identity/Account/ForceChangePassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountForceChangePasswordPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountForceChangePasswordPost$Plain$Response(params?: {
    context?: HttpContext
    body?: ChangePasswordViewModel
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountForceChangePasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountForceChangePasswordPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountForceChangePasswordPost$Plain(params?: {
    context?: HttpContext
    body?: ChangePasswordViewModel
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountForceChangePasswordPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountForceChangePasswordPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountForceChangePasswordPost$Json$Response(params?: {
    context?: HttpContext
    body?: ChangePasswordViewModel
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountForceChangePasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountForceChangePasswordPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountForceChangePasswordPost$Json(params?: {
    context?: HttpContext
    body?: ChangePasswordViewModel
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountForceChangePasswordPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountCheckAccountPasswordPost
   */
  static readonly IdentityAccountCheckAccountPasswordPostPath = '/identity/Account/CheckAccountPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountCheckAccountPasswordPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountCheckAccountPasswordPost$Plain$Response(params?: {
    context?: HttpContext
    body?: CheckAccountPasswordViewModel
  }
): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountCheckAccountPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountCheckAccountPasswordPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountCheckAccountPasswordPost$Plain(params?: {
    context?: HttpContext
    body?: CheckAccountPasswordViewModel
  }
): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.identityAccountCheckAccountPasswordPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountCheckAccountPasswordPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountCheckAccountPasswordPost$Json$Response(params?: {
    context?: HttpContext
    body?: CheckAccountPasswordViewModel
  }
): Observable<StrictHttpResponse<ResultSearchAccountViewModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountCheckAccountPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultSearchAccountViewModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountCheckAccountPasswordPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountCheckAccountPasswordPost$Json(params?: {
    context?: HttpContext
    body?: CheckAccountPasswordViewModel
  }
): Observable<ResultSearchAccountViewModelResponseModel> {

    return this.identityAccountCheckAccountPasswordPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResultSearchAccountViewModelResponseModel>) => r.body as ResultSearchAccountViewModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountGetBirthdayByIdAccountIdGet
   */
  static readonly IdentityAccountGetBirthdayByIdAccountIdGetPath = '/identity/Account/GetBirthdayById/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountGetBirthdayByIdAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetBirthdayByIdAccountIdGet$Plain$Response(params: {
    accountId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<DateTimeNullableResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountGetBirthdayByIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DateTimeNullableResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountGetBirthdayByIdAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetBirthdayByIdAccountIdGet$Plain(params: {
    accountId: string;
    context?: HttpContext
  }
): Observable<DateTimeNullableResponseModel> {

    return this.identityAccountGetBirthdayByIdAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DateTimeNullableResponseModel>) => r.body as DateTimeNullableResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountGetBirthdayByIdAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetBirthdayByIdAccountIdGet$Json$Response(params: {
    accountId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<DateTimeNullableResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountGetBirthdayByIdAccountIdGetPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DateTimeNullableResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountGetBirthdayByIdAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetBirthdayByIdAccountIdGet$Json(params: {
    accountId: string;
    context?: HttpContext
  }
): Observable<DateTimeNullableResponseModel> {

    return this.identityAccountGetBirthdayByIdAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DateTimeNullableResponseModel>) => r.body as DateTimeNullableResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountGetRolesByIdentityIdIdentityIdGet
   */
  static readonly IdentityAccountGetRolesByIdentityIdIdentityIdGetPath = '/identity/Account/GetRolesByIdentityId/{identityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountGetRolesByIdentityIdIdentityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetRolesByIdentityIdIdentityIdGet$Plain$Response(params: {
    identityId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StringListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountGetRolesByIdentityIdIdentityIdGetPath, 'get');
    if (params) {
      rb.path('identityId', params.identityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountGetRolesByIdentityIdIdentityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetRolesByIdentityIdIdentityIdGet$Plain(params: {
    identityId: string;
    context?: HttpContext
  }
): Observable<StringListResponseModel> {

    return this.identityAccountGetRolesByIdentityIdIdentityIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringListResponseModel>) => r.body as StringListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountGetRolesByIdentityIdIdentityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetRolesByIdentityIdIdentityIdGet$Json$Response(params: {
    identityId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StringListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountGetRolesByIdentityIdIdentityIdGetPath, 'get');
    if (params) {
      rb.path('identityId', params.identityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountGetRolesByIdentityIdIdentityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetRolesByIdentityIdIdentityIdGet$Json(params: {
    identityId: string;
    context?: HttpContext
  }
): Observable<StringListResponseModel> {

    return this.identityAccountGetRolesByIdentityIdIdentityIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringListResponseModel>) => r.body as StringListResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountGetIdentityIdByRoleGet
   */
  static readonly IdentityAccountGetIdentityIdByRoleGetPath = '/identity/Account/GetIdentityIdByRole';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountGetIdentityIdByRoleGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetIdentityIdByRoleGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GuidListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountGetIdentityIdByRoleGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GuidListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountGetIdentityIdByRoleGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetIdentityIdByRoleGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<GuidListResponseModel> {

    return this.identityAccountGetIdentityIdByRoleGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GuidListResponseModel>) => r.body as GuidListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountGetIdentityIdByRoleGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetIdentityIdByRoleGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GuidListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountGetIdentityIdByRoleGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GuidListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountGetIdentityIdByRoleGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountGetIdentityIdByRoleGet$Json(params?: {
    context?: HttpContext
  }
): Observable<GuidListResponseModel> {

    return this.identityAccountGetIdentityIdByRoleGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GuidListResponseModel>) => r.body as GuidListResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountUpdateDefaultValuesPut
   */
  static readonly IdentityAccountUpdateDefaultValuesPutPath = '/identity/Account/UpdateDefaultValues';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountUpdateDefaultValuesPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountUpdateDefaultValuesPut$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountUpdateDefaultValuesPutPath, 'put');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountUpdateDefaultValuesPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountUpdateDefaultValuesPut$Plain(params?: {
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountUpdateDefaultValuesPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountUpdateDefaultValuesPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountUpdateDefaultValuesPut$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountUpdateDefaultValuesPutPath, 'put');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountUpdateDefaultValuesPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountUpdateDefaultValuesPut$Json(params?: {
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountUpdateDefaultValuesPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountAddServicePost
   */
  static readonly IdentityAccountAddServicePostPath = '/identity/Account/AddService';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAddServicePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAddServicePost$Plain$Response(params?: {
    context?: HttpContext
    body?: string
  }
): Observable<StrictHttpResponse<ApplicationServicesResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountAddServicePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationServicesResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAddServicePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAddServicePost$Plain(params?: {
    context?: HttpContext
    body?: string
  }
): Observable<ApplicationServicesResponseModel> {

    return this.identityAccountAddServicePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationServicesResponseModel>) => r.body as ApplicationServicesResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAddServicePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAddServicePost$Json$Response(params?: {
    context?: HttpContext
    body?: string
  }
): Observable<StrictHttpResponse<ApplicationServicesResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountAddServicePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationServicesResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAddServicePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAddServicePost$Json(params?: {
    context?: HttpContext
    body?: string
  }
): Observable<ApplicationServicesResponseModel> {

    return this.identityAccountAddServicePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationServicesResponseModel>) => r.body as ApplicationServicesResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountAllServiceGet
   */
  static readonly IdentityAccountAllServiceGetPath = '/identity/Account/AllService';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAllServiceGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountAllServiceGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ApplicationServicesListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountAllServiceGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationServicesListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAllServiceGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountAllServiceGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<ApplicationServicesListResponseModel> {

    return this.identityAccountAllServiceGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationServicesListResponseModel>) => r.body as ApplicationServicesListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAllServiceGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountAllServiceGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ApplicationServicesListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountAllServiceGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationServicesListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAllServiceGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountAllServiceGet$Json(params?: {
    context?: HttpContext
  }
): Observable<ApplicationServicesListResponseModel> {

    return this.identityAccountAllServiceGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationServicesListResponseModel>) => r.body as ApplicationServicesListResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountDeleteServiceServiceIdDelete
   */
  static readonly IdentityAccountDeleteServiceServiceIdDeletePath = '/identity/Account/DeleteService/{serviceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountDeleteServiceServiceIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountDeleteServiceServiceIdDelete$Plain$Response(params: {
    serviceId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountDeleteServiceServiceIdDeletePath, 'delete');
    if (params) {
      rb.path('serviceId', params.serviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountDeleteServiceServiceIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountDeleteServiceServiceIdDelete$Plain(params: {
    serviceId: string;
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountDeleteServiceServiceIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountDeleteServiceServiceIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountDeleteServiceServiceIdDelete$Json$Response(params: {
    serviceId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.IdentityAccountDeleteServiceServiceIdDeletePath, 'delete');
    if (params) {
      rb.path('serviceId', params.serviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountDeleteServiceServiceIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountDeleteServiceServiceIdDelete$Json(params: {
    serviceId: string;
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.identityAccountDeleteServiceServiceIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

}
