/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PlaceCreateModel } from '../models/place-create-model';
import { PlaceDeleteModel } from '../models/place-delete-model';
import { PlaceListResponseModel } from '../models/place-list-response-model';
import { PlacePaginationModelResponseModel } from '../models/place-pagination-model-response-model';
import { PlaceResponseModel } from '../models/place-response-model';
import { PlaceUpdateModel } from '../models/place-update-model';

@Injectable({
  providedIn: 'root',
})
export class PlaceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation addressPlacePageGet
   */
  static readonly AddressPlacePageGetPath = '/address/Place/Page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressPlacePageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlacePageGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PlacePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.AddressPlacePageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlacePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressPlacePageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlacePageGet$Plain(params?: {
    page?: number;
    limit?: number;
    context?: HttpContext
  }
): Observable<PlacePaginationModelResponseModel> {

    return this.addressPlacePageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PlacePaginationModelResponseModel>) => r.body as PlacePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressPlacePageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlacePageGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PlacePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.AddressPlacePageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlacePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressPlacePageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlacePageGet$Json(params?: {
    page?: number;
    limit?: number;
    context?: HttpContext
  }
): Observable<PlacePaginationModelResponseModel> {

    return this.addressPlacePageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PlacePaginationModelResponseModel>) => r.body as PlacePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation addressPlaceByIdentityUserIdentityUserIdGet
   */
  static readonly AddressPlaceByIdentityUserIdentityUserIdGetPath = '/address/Place/ByIdentityUser/{identityUserId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressPlaceByIdentityUserIdentityUserIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceByIdentityUserIdentityUserIdGet$Plain$Response(params: {
    identityUserId: string;
    page?: number;
    limit?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PlacePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.AddressPlaceByIdentityUserIdentityUserIdGetPath, 'get');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlacePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressPlaceByIdentityUserIdentityUserIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceByIdentityUserIdentityUserIdGet$Plain(params: {
    identityUserId: string;
    page?: number;
    limit?: number;
    context?: HttpContext
  }
): Observable<PlacePaginationModelResponseModel> {

    return this.addressPlaceByIdentityUserIdentityUserIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PlacePaginationModelResponseModel>) => r.body as PlacePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressPlaceByIdentityUserIdentityUserIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceByIdentityUserIdentityUserIdGet$Json$Response(params: {
    identityUserId: string;
    page?: number;
    limit?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PlacePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.AddressPlaceByIdentityUserIdentityUserIdGetPath, 'get');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlacePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressPlaceByIdentityUserIdentityUserIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceByIdentityUserIdentityUserIdGet$Json(params: {
    identityUserId: string;
    page?: number;
    limit?: number;
    context?: HttpContext
  }
): Observable<PlacePaginationModelResponseModel> {

    return this.addressPlaceByIdentityUserIdentityUserIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PlacePaginationModelResponseModel>) => r.body as PlacePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation addressPlaceByEmailEmailGet
   */
  static readonly AddressPlaceByEmailEmailGetPath = '/address/Place/ByEmail/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressPlaceByEmailEmailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceByEmailEmailGet$Plain$Response(params: {
    email: string;
    page?: number;
    limit?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PlacePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.AddressPlaceByEmailEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlacePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressPlaceByEmailEmailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceByEmailEmailGet$Plain(params: {
    email: string;
    page?: number;
    limit?: number;
    context?: HttpContext
  }
): Observable<PlacePaginationModelResponseModel> {

    return this.addressPlaceByEmailEmailGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PlacePaginationModelResponseModel>) => r.body as PlacePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressPlaceByEmailEmailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceByEmailEmailGet$Json$Response(params: {
    email: string;
    page?: number;
    limit?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PlacePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.AddressPlaceByEmailEmailGetPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlacePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressPlaceByEmailEmailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceByEmailEmailGet$Json(params: {
    email: string;
    page?: number;
    limit?: number;
    context?: HttpContext
  }
): Observable<PlacePaginationModelResponseModel> {

    return this.addressPlaceByEmailEmailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PlacePaginationModelResponseModel>) => r.body as PlacePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation addressPlaceByIdIdGet
   */
  static readonly AddressPlaceByIdIdGetPath = '/address/Place/ById/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressPlaceByIdIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceByIdIdGet$Plain$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PlaceResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.AddressPlaceByIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlaceResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressPlaceByIdIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceByIdIdGet$Plain(params: {
    id: string;
    context?: HttpContext
  }
): Observable<PlaceResponseModel> {

    return this.addressPlaceByIdIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PlaceResponseModel>) => r.body as PlaceResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressPlaceByIdIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceByIdIdGet$Json$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PlaceResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.AddressPlaceByIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlaceResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressPlaceByIdIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceByIdIdGet$Json(params: {
    id: string;
    context?: HttpContext
  }
): Observable<PlaceResponseModel> {

    return this.addressPlaceByIdIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PlaceResponseModel>) => r.body as PlaceResponseModel)
    );
  }

  /**
   * Path part for operation addressPlaceDefaultIdentityUserIdGet
   */
  static readonly AddressPlaceDefaultIdentityUserIdGetPath = '/address/Place/Default/{identityUserId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressPlaceDefaultIdentityUserIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceDefaultIdentityUserIdGet$Plain$Response(params: {
    identityUserId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PlaceResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.AddressPlaceDefaultIdentityUserIdGetPath, 'get');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlaceResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressPlaceDefaultIdentityUserIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceDefaultIdentityUserIdGet$Plain(params: {
    identityUserId: string;
    context?: HttpContext
  }
): Observable<PlaceResponseModel> {

    return this.addressPlaceDefaultIdentityUserIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PlaceResponseModel>) => r.body as PlaceResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressPlaceDefaultIdentityUserIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceDefaultIdentityUserIdGet$Json$Response(params: {
    identityUserId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PlaceResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.AddressPlaceDefaultIdentityUserIdGetPath, 'get');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlaceResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressPlaceDefaultIdentityUserIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceDefaultIdentityUserIdGet$Json(params: {
    identityUserId: string;
    context?: HttpContext
  }
): Observable<PlaceResponseModel> {

    return this.addressPlaceDefaultIdentityUserIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PlaceResponseModel>) => r.body as PlaceResponseModel)
    );
  }

  /**
   * Path part for operation addressPlaceCreatePost
   */
  static readonly AddressPlaceCreatePostPath = '/address/Place/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressPlaceCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addressPlaceCreatePost$Plain$Response(params?: {
    context?: HttpContext
    body?: PlaceCreateModel
  }
): Observable<StrictHttpResponse<PlaceResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.AddressPlaceCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlaceResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressPlaceCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addressPlaceCreatePost$Plain(params?: {
    context?: HttpContext
    body?: PlaceCreateModel
  }
): Observable<PlaceResponseModel> {

    return this.addressPlaceCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PlaceResponseModel>) => r.body as PlaceResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressPlaceCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addressPlaceCreatePost$Json$Response(params?: {
    context?: HttpContext
    body?: PlaceCreateModel
  }
): Observable<StrictHttpResponse<PlaceResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.AddressPlaceCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlaceResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressPlaceCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addressPlaceCreatePost$Json(params?: {
    context?: HttpContext
    body?: PlaceCreateModel
  }
): Observable<PlaceResponseModel> {

    return this.addressPlaceCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PlaceResponseModel>) => r.body as PlaceResponseModel)
    );
  }

  /**
   * Path part for operation addressPlaceUpdateIdentityUserIdPlaceIdPut
   */
  static readonly AddressPlaceUpdateIdentityUserIdPlaceIdPutPath = '/address/Place/Update/{identityUserId}/{placeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressPlaceUpdateIdentityUserIdPlaceIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addressPlaceUpdateIdentityUserIdPlaceIdPut$Plain$Response(params: {
    identityUserId: string;
    placeId: string;
    context?: HttpContext
    body?: PlaceUpdateModel
  }
): Observable<StrictHttpResponse<PlaceResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.AddressPlaceUpdateIdentityUserIdPlaceIdPutPath, 'put');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
      rb.path('placeId', params.placeId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlaceResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressPlaceUpdateIdentityUserIdPlaceIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addressPlaceUpdateIdentityUserIdPlaceIdPut$Plain(params: {
    identityUserId: string;
    placeId: string;
    context?: HttpContext
    body?: PlaceUpdateModel
  }
): Observable<PlaceResponseModel> {

    return this.addressPlaceUpdateIdentityUserIdPlaceIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PlaceResponseModel>) => r.body as PlaceResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressPlaceUpdateIdentityUserIdPlaceIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addressPlaceUpdateIdentityUserIdPlaceIdPut$Json$Response(params: {
    identityUserId: string;
    placeId: string;
    context?: HttpContext
    body?: PlaceUpdateModel
  }
): Observable<StrictHttpResponse<PlaceResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.AddressPlaceUpdateIdentityUserIdPlaceIdPutPath, 'put');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
      rb.path('placeId', params.placeId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlaceResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressPlaceUpdateIdentityUserIdPlaceIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addressPlaceUpdateIdentityUserIdPlaceIdPut$Json(params: {
    identityUserId: string;
    placeId: string;
    context?: HttpContext
    body?: PlaceUpdateModel
  }
): Observable<PlaceResponseModel> {

    return this.addressPlaceUpdateIdentityUserIdPlaceIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PlaceResponseModel>) => r.body as PlaceResponseModel)
    );
  }

  /**
   * Path part for operation addressPlaceDeleteIdentityUserIdDelete
   */
  static readonly AddressPlaceDeleteIdentityUserIdDeletePath = '/address/Place/Delete/{identityUserId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressPlaceDeleteIdentityUserIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addressPlaceDeleteIdentityUserIdDelete$Plain$Response(params: {
    identityUserId: string;
    context?: HttpContext
    body?: PlaceDeleteModel
  }
): Observable<StrictHttpResponse<PlaceListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.AddressPlaceDeleteIdentityUserIdDeletePath, 'delete');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlaceListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressPlaceDeleteIdentityUserIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addressPlaceDeleteIdentityUserIdDelete$Plain(params: {
    identityUserId: string;
    context?: HttpContext
    body?: PlaceDeleteModel
  }
): Observable<PlaceListResponseModel> {

    return this.addressPlaceDeleteIdentityUserIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PlaceListResponseModel>) => r.body as PlaceListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressPlaceDeleteIdentityUserIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addressPlaceDeleteIdentityUserIdDelete$Json$Response(params: {
    identityUserId: string;
    context?: HttpContext
    body?: PlaceDeleteModel
  }
): Observable<StrictHttpResponse<PlaceListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.AddressPlaceDeleteIdentityUserIdDeletePath, 'delete');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlaceListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressPlaceDeleteIdentityUserIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addressPlaceDeleteIdentityUserIdDelete$Json(params: {
    identityUserId: string;
    context?: HttpContext
    body?: PlaceDeleteModel
  }
): Observable<PlaceListResponseModel> {

    return this.addressPlaceDeleteIdentityUserIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PlaceListResponseModel>) => r.body as PlaceListResponseModel)
    );
  }

  /**
   * Path part for operation addressPlaceSetDefaultIdentityUserIdPlaceIdPost
   */
  static readonly AddressPlaceSetDefaultIdentityUserIdPlaceIdPostPath = '/address/Place/SetDefault/{identityUserId}/{placeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressPlaceSetDefaultIdentityUserIdPlaceIdPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceSetDefaultIdentityUserIdPlaceIdPost$Plain$Response(params: {
    identityUserId: string;
    placeId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PlaceResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.AddressPlaceSetDefaultIdentityUserIdPlaceIdPostPath, 'post');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
      rb.path('placeId', params.placeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlaceResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressPlaceSetDefaultIdentityUserIdPlaceIdPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceSetDefaultIdentityUserIdPlaceIdPost$Plain(params: {
    identityUserId: string;
    placeId: string;
    context?: HttpContext
  }
): Observable<PlaceResponseModel> {

    return this.addressPlaceSetDefaultIdentityUserIdPlaceIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PlaceResponseModel>) => r.body as PlaceResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressPlaceSetDefaultIdentityUserIdPlaceIdPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceSetDefaultIdentityUserIdPlaceIdPost$Json$Response(params: {
    identityUserId: string;
    placeId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PlaceResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.AddressPlaceSetDefaultIdentityUserIdPlaceIdPostPath, 'post');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
      rb.path('placeId', params.placeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlaceResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressPlaceSetDefaultIdentityUserIdPlaceIdPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceSetDefaultIdentityUserIdPlaceIdPost$Json(params: {
    identityUserId: string;
    placeId: string;
    context?: HttpContext
  }
): Observable<PlaceResponseModel> {

    return this.addressPlaceSetDefaultIdentityUserIdPlaceIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PlaceResponseModel>) => r.body as PlaceResponseModel)
    );
  }

  /**
   * Path part for operation addressPlaceRemoveDefaultIdentityUserIdPlaceIdPost
   */
  static readonly AddressPlaceRemoveDefaultIdentityUserIdPlaceIdPostPath = '/address/Place/RemoveDefault/{identityUserId}/{placeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressPlaceRemoveDefaultIdentityUserIdPlaceIdPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceRemoveDefaultIdentityUserIdPlaceIdPost$Plain$Response(params: {
    identityUserId: string;
    placeId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PlaceResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.AddressPlaceRemoveDefaultIdentityUserIdPlaceIdPostPath, 'post');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
      rb.path('placeId', params.placeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlaceResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressPlaceRemoveDefaultIdentityUserIdPlaceIdPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceRemoveDefaultIdentityUserIdPlaceIdPost$Plain(params: {
    identityUserId: string;
    placeId: string;
    context?: HttpContext
  }
): Observable<PlaceResponseModel> {

    return this.addressPlaceRemoveDefaultIdentityUserIdPlaceIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PlaceResponseModel>) => r.body as PlaceResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressPlaceRemoveDefaultIdentityUserIdPlaceIdPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceRemoveDefaultIdentityUserIdPlaceIdPost$Json$Response(params: {
    identityUserId: string;
    placeId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PlaceResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.AddressPlaceRemoveDefaultIdentityUserIdPlaceIdPostPath, 'post');
    if (params) {
      rb.path('identityUserId', params.identityUserId, {});
      rb.path('placeId', params.placeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PlaceResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressPlaceRemoveDefaultIdentityUserIdPlaceIdPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressPlaceRemoveDefaultIdentityUserIdPlaceIdPost$Json(params: {
    identityUserId: string;
    placeId: string;
    context?: HttpContext
  }
): Observable<PlaceResponseModel> {

    return this.addressPlaceRemoveDefaultIdentityUserIdPlaceIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PlaceResponseModel>) => r.body as PlaceResponseModel)
    );
  }

}
