import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter, map } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "angular.identity";

  isShowBlur = false;

  constructor(private router: Router) {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd), map(e => e as NavigationEnd)).subscribe((e) => {
      if (e.url.indexOf('/home') >= 0 || e.urlAfterRedirects.indexOf('/home') >= 0) {
        this.isShowBlur = true;
      }
      else this.isShowBlur = false;
    })
  }
}
