/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanResponseModel } from '../models/boolean-response-model';
import { Int32ResponseModel } from '../models/int-32-response-model';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation emailDatabaseCreatedPost
   */
  static readonly EmailDatabaseCreatedPostPath = '/email/Database/Created';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailDatabaseCreatedPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailDatabaseCreatedPost$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, DatabaseService.EmailDatabaseCreatedPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailDatabaseCreatedPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailDatabaseCreatedPost$Plain(params?: {
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.emailDatabaseCreatedPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailDatabaseCreatedPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailDatabaseCreatedPost$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, DatabaseService.EmailDatabaseCreatedPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailDatabaseCreatedPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailDatabaseCreatedPost$Json(params?: {
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.emailDatabaseCreatedPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation emailDatabaseDeletedDelete
   */
  static readonly EmailDatabaseDeletedDeletePath = '/email/Database/Deleted';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailDatabaseDeletedDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailDatabaseDeletedDelete$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, DatabaseService.EmailDatabaseDeletedDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailDatabaseDeletedDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailDatabaseDeletedDelete$Plain(params?: {
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.emailDatabaseDeletedDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailDatabaseDeletedDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailDatabaseDeletedDelete$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, DatabaseService.EmailDatabaseDeletedDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailDatabaseDeletedDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailDatabaseDeletedDelete$Json(params?: {
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.emailDatabaseDeletedDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * Path part for operation emailDatabaseClearDelete
   */
  static readonly EmailDatabaseClearDeletePath = '/email/Database/Clear';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailDatabaseClearDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailDatabaseClearDelete$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Int32ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, DatabaseService.EmailDatabaseClearDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int32ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailDatabaseClearDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailDatabaseClearDelete$Plain(params?: {
    context?: HttpContext
  }
): Observable<Int32ResponseModel> {

    return this.emailDatabaseClearDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ResponseModel>) => r.body as Int32ResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailDatabaseClearDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailDatabaseClearDelete$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Int32ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, DatabaseService.EmailDatabaseClearDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int32ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailDatabaseClearDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailDatabaseClearDelete$Json(params?: {
    context?: HttpContext
  }
): Observable<Int32ResponseModel> {

    return this.emailDatabaseClearDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ResponseModel>) => r.body as Int32ResponseModel)
    );
  }

}
