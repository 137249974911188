/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApplicationRolePaginationModelResponseModel } from '../models/application-role-pagination-model-response-model';
import { ApplicationRoleResponseModel } from '../models/application-role-response-model';
import { ApplicationUserIListResponseModel } from '../models/application-user-i-list-response-model';
import { Int32ResponseModel } from '../models/int-32-response-model';

@Injectable({
  providedIn: 'root',
})
export class RoleService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation identityRoleCountGet
   */
  static readonly IdentityRoleCountGetPath = '/identity/Role/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityRoleCountGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRoleCountGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Int32ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.IdentityRoleCountGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int32ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityRoleCountGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRoleCountGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<Int32ResponseModel> {

    return this.identityRoleCountGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ResponseModel>) => r.body as Int32ResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityRoleCountGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRoleCountGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Int32ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.IdentityRoleCountGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int32ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityRoleCountGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRoleCountGet$Json(params?: {
    context?: HttpContext
  }
): Observable<Int32ResponseModel> {

    return this.identityRoleCountGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ResponseModel>) => r.body as Int32ResponseModel)
    );
  }

  /**
   * Path part for operation identityRolePageGet
   */
  static readonly IdentityRolePageGetPath = '/identity/Role/page';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityRolePageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRolePageGet$Plain$Response(params?: {
    page?: number;
    limit?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ApplicationRolePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.IdentityRolePageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationRolePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityRolePageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRolePageGet$Plain(params?: {
    page?: number;
    limit?: number;
    context?: HttpContext
  }
): Observable<ApplicationRolePaginationModelResponseModel> {

    return this.identityRolePageGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationRolePaginationModelResponseModel>) => r.body as ApplicationRolePaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityRolePageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRolePageGet$Json$Response(params?: {
    page?: number;
    limit?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ApplicationRolePaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.IdentityRolePageGetPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationRolePaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityRolePageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRolePageGet$Json(params?: {
    page?: number;
    limit?: number;
    context?: HttpContext
  }
): Observable<ApplicationRolePaginationModelResponseModel> {

    return this.identityRolePageGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationRolePaginationModelResponseModel>) => r.body as ApplicationRolePaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation identityRoleIdIdGet
   */
  static readonly IdentityRoleIdIdGetPath = '/identity/Role/id/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityRoleIdIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRoleIdIdGet$Plain$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ApplicationRoleResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.IdentityRoleIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationRoleResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityRoleIdIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRoleIdIdGet$Plain(params: {
    id: string;
    context?: HttpContext
  }
): Observable<ApplicationRoleResponseModel> {

    return this.identityRoleIdIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationRoleResponseModel>) => r.body as ApplicationRoleResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityRoleIdIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRoleIdIdGet$Json$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ApplicationRoleResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.IdentityRoleIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationRoleResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityRoleIdIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRoleIdIdGet$Json(params: {
    id: string;
    context?: HttpContext
  }
): Observable<ApplicationRoleResponseModel> {

    return this.identityRoleIdIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationRoleResponseModel>) => r.body as ApplicationRoleResponseModel)
    );
  }

  /**
   * Path part for operation identityRoleNameNameGet
   */
  static readonly IdentityRoleNameNameGetPath = '/identity/Role/name/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityRoleNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRoleNameNameGet$Plain$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ApplicationRoleResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.IdentityRoleNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationRoleResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityRoleNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRoleNameNameGet$Plain(params: {
    name: string;
    context?: HttpContext
  }
): Observable<ApplicationRoleResponseModel> {

    return this.identityRoleNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationRoleResponseModel>) => r.body as ApplicationRoleResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityRoleNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRoleNameNameGet$Json$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ApplicationRoleResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.IdentityRoleNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationRoleResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityRoleNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRoleNameNameGet$Json(params: {
    name: string;
    context?: HttpContext
  }
): Observable<ApplicationRoleResponseModel> {

    return this.identityRoleNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationRoleResponseModel>) => r.body as ApplicationRoleResponseModel)
    );
  }

  /**
   * Path part for operation identityRoleNameNameUsersGet
   */
  static readonly IdentityRoleNameNameUsersGetPath = '/identity/Role/name/{name}/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityRoleNameNameUsersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRoleNameNameUsersGet$Plain$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ApplicationUserIListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.IdentityRoleNameNameUsersGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserIListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityRoleNameNameUsersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRoleNameNameUsersGet$Plain(params: {
    name: string;
    context?: HttpContext
  }
): Observable<ApplicationUserIListResponseModel> {

    return this.identityRoleNameNameUsersGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserIListResponseModel>) => r.body as ApplicationUserIListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityRoleNameNameUsersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRoleNameNameUsersGet$Json$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ApplicationUserIListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.IdentityRoleNameNameUsersGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserIListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityRoleNameNameUsersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRoleNameNameUsersGet$Json(params: {
    name: string;
    context?: HttpContext
  }
): Observable<ApplicationUserIListResponseModel> {

    return this.identityRoleNameNameUsersGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserIListResponseModel>) => r.body as ApplicationUserIListResponseModel)
    );
  }

  /**
   * Path part for operation identityRoleNamePost
   */
  static readonly IdentityRoleNamePostPath = '/identity/Role/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityRoleNamePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRoleNamePost$Plain$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ApplicationRoleResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.IdentityRoleNamePostPath, 'post');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationRoleResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityRoleNamePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRoleNamePost$Plain(params: {
    name: string;
    context?: HttpContext
  }
): Observable<ApplicationRoleResponseModel> {

    return this.identityRoleNamePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationRoleResponseModel>) => r.body as ApplicationRoleResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityRoleNamePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRoleNamePost$Json$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ApplicationRoleResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.IdentityRoleNamePostPath, 'post');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationRoleResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityRoleNamePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityRoleNamePost$Json(params: {
    name: string;
    context?: HttpContext
  }
): Observable<ApplicationRoleResponseModel> {

    return this.identityRoleNamePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationRoleResponseModel>) => r.body as ApplicationRoleResponseModel)
    );
  }

}
