import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TranslationService } from "./core/services/translate.service";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HandleErrorInterceptor } from "./core/interceptor/handle-error.interceptor";
import { SocialLoginModule, FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig } from 'angularx-social-login';
import { environment } from "src/environments/environment";
import { AddressModule } from 'src/app/core/address-api/address.module';
import { IdentityModule } from 'src/app/core/identity-api/identity.module';
import { EmailModule } from 'src/app/core/email-api/email.module';
import { ToastModule } from 'primeng/toast';

import { LoadingComponent } from "./loading/loading.component";
import { PopUpComponent } from "./pop-up/pop-up.component";
import { MessageService } from "primeng/api";


export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, LoadingComponent, PopUpComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SocialLoginModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationService,
        deps: [HttpClient],
      },
    }),
    IdentityModule.forRoot({ rootUrl: environment.identityApiUrl }),
    EmailModule.forRoot({ rootUrl: environment.emailApiUrl }),
    AddressModule.forRoot({ rootUrl: environment.addressApiUrl }),
    ToastModule
  ],
  providers: [
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HandleErrorInterceptor,
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookAppId),
          },
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleAppId),
          },
        ],
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
