import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { catchError, filter, map, Observable, of } from "rxjs";
import { TOKEN } from "src/app/core/const";

@Injectable()
export class HandleErrorInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.context.get(TOKEN)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${request.context.get(TOKEN)}`,
        },
      });
    }

    return next.handle(request).pipe(
      map((res: any) => {
        if (res && res.body && res.body['dataStr']) {
          try { res.body['data'] = JSON.parse(atob(res.body['dataStr'])); }
          catch { res.body['data'] = atob(res.body['dataStr']); }
          res.body['dataStr'] = null;
        }
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status == 500) {
          return of(new HttpResponse({ body: err.error, status: 0 }));
        }
        throw err;
      })
    );
  }
}
