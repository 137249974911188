/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AppleViewModel } from '../models/apple-view-model';
import { ApplicationUserApplesResponseModel } from '../models/application-user-apples-response-model';
import { ApplicationUserResponseModel } from '../models/application-user-response-model';
import { CreateUserAppleInputModel } from '../models/create-user-apple-input-model';
import { LoginResultModelResponseModel } from '../models/login-result-model-response-model';
import { StringResponseModel } from '../models/string-response-model';

@Injectable({
  providedIn: 'root',
})
export class AppleService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation identityAccountApplePreRegisterPost
   */
  static readonly IdentityAccountApplePreRegisterPostPath = '/identity/Account/Apple/PreRegister';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountApplePreRegisterPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountApplePreRegisterPost$Plain$Response(params?: {
    context?: HttpContext
    body?: AppleViewModel
  }
): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppleService.IdentityAccountApplePreRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountApplePreRegisterPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountApplePreRegisterPost$Plain(params?: {
    context?: HttpContext
    body?: AppleViewModel
  }
): Observable<StringResponseModel> {

    return this.identityAccountApplePreRegisterPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountApplePreRegisterPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountApplePreRegisterPost$Json$Response(params?: {
    context?: HttpContext
    body?: AppleViewModel
  }
): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppleService.IdentityAccountApplePreRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountApplePreRegisterPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountApplePreRegisterPost$Json(params?: {
    context?: HttpContext
    body?: AppleViewModel
  }
): Observable<StringResponseModel> {

    return this.identityAccountApplePreRegisterPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountAppleRegisterPost
   */
  static readonly IdentityAccountAppleRegisterPostPath = '/identity/Account/Apple/Register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAppleRegisterPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAppleRegisterPost$Plain$Response(params?: {
    context?: HttpContext
    body?: AppleViewModel
  }
): Observable<StrictHttpResponse<LoginResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppleService.IdentityAccountAppleRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAppleRegisterPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAppleRegisterPost$Plain(params?: {
    context?: HttpContext
    body?: AppleViewModel
  }
): Observable<LoginResultModelResponseModel> {

    return this.identityAccountAppleRegisterPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LoginResultModelResponseModel>) => r.body as LoginResultModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAppleRegisterPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAppleRegisterPost$Json$Response(params?: {
    context?: HttpContext
    body?: AppleViewModel
  }
): Observable<StrictHttpResponse<LoginResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppleService.IdentityAccountAppleRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAppleRegisterPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAppleRegisterPost$Json(params?: {
    context?: HttpContext
    body?: AppleViewModel
  }
): Observable<LoginResultModelResponseModel> {

    return this.identityAccountAppleRegisterPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LoginResultModelResponseModel>) => r.body as LoginResultModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountAppleCreateUserApplePost
   */
  static readonly IdentityAccountAppleCreateUserApplePostPath = '/identity/Account/Apple/CreateUserApple';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAppleCreateUserApplePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAppleCreateUserApplePost$Plain$Response(params?: {
    context?: HttpContext
    body?: CreateUserAppleInputModel
  }
): Observable<StrictHttpResponse<ApplicationUserApplesResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppleService.IdentityAccountAppleCreateUserApplePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserApplesResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAppleCreateUserApplePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAppleCreateUserApplePost$Plain(params?: {
    context?: HttpContext
    body?: CreateUserAppleInputModel
  }
): Observable<ApplicationUserApplesResponseModel> {

    return this.identityAccountAppleCreateUserApplePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserApplesResponseModel>) => r.body as ApplicationUserApplesResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAppleCreateUserApplePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAppleCreateUserApplePost$Json$Response(params?: {
    context?: HttpContext
    body?: CreateUserAppleInputModel
  }
): Observable<StrictHttpResponse<ApplicationUserApplesResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppleService.IdentityAccountAppleCreateUserApplePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserApplesResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAppleCreateUserApplePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAppleCreateUserApplePost$Json(params?: {
    context?: HttpContext
    body?: CreateUserAppleInputModel
  }
): Observable<ApplicationUserApplesResponseModel> {

    return this.identityAccountAppleCreateUserApplePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserApplesResponseModel>) => r.body as ApplicationUserApplesResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountAppleGetUserAppleByIdIdGet
   */
  static readonly IdentityAccountAppleGetUserAppleByIdIdGetPath = '/identity/Account/Apple/GetUserAppleById/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAppleGetUserAppleByIdIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountAppleGetUserAppleByIdIdGet$Plain$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ApplicationUserApplesResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppleService.IdentityAccountAppleGetUserAppleByIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserApplesResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAppleGetUserAppleByIdIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountAppleGetUserAppleByIdIdGet$Plain(params: {
    id: string;
    context?: HttpContext
  }
): Observable<ApplicationUserApplesResponseModel> {

    return this.identityAccountAppleGetUserAppleByIdIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserApplesResponseModel>) => r.body as ApplicationUserApplesResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAppleGetUserAppleByIdIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountAppleGetUserAppleByIdIdGet$Json$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ApplicationUserApplesResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppleService.IdentityAccountAppleGetUserAppleByIdIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserApplesResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAppleGetUserAppleByIdIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountAppleGetUserAppleByIdIdGet$Json(params: {
    id: string;
    context?: HttpContext
  }
): Observable<ApplicationUserApplesResponseModel> {

    return this.identityAccountAppleGetUserAppleByIdIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserApplesResponseModel>) => r.body as ApplicationUserApplesResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountAppleDeleteUserAppleByIdIdDelete
   */
  static readonly IdentityAccountAppleDeleteUserAppleByIdIdDeletePath = '/identity/Account/Apple/DeleteUserAppleById/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAppleDeleteUserAppleByIdIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountAppleDeleteUserAppleByIdIdDelete$Plain$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ApplicationUserApplesResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppleService.IdentityAccountAppleDeleteUserAppleByIdIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserApplesResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAppleDeleteUserAppleByIdIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountAppleDeleteUserAppleByIdIdDelete$Plain(params: {
    id: string;
    context?: HttpContext
  }
): Observable<ApplicationUserApplesResponseModel> {

    return this.identityAccountAppleDeleteUserAppleByIdIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserApplesResponseModel>) => r.body as ApplicationUserApplesResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAppleDeleteUserAppleByIdIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountAppleDeleteUserAppleByIdIdDelete$Json$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ApplicationUserApplesResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppleService.IdentityAccountAppleDeleteUserAppleByIdIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserApplesResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAppleDeleteUserAppleByIdIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountAppleDeleteUserAppleByIdIdDelete$Json(params: {
    id: string;
    context?: HttpContext
  }
): Observable<ApplicationUserApplesResponseModel> {

    return this.identityAccountAppleDeleteUserAppleByIdIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserApplesResponseModel>) => r.body as ApplicationUserApplesResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountAppleLoginPost
   */
  static readonly IdentityAccountAppleLoginPostPath = '/identity/Account/Apple/Login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAppleLoginPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAppleLoginPost$Plain$Response(params?: {
    context?: HttpContext
    body?: AppleViewModel
  }
): Observable<StrictHttpResponse<LoginResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppleService.IdentityAccountAppleLoginPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAppleLoginPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAppleLoginPost$Plain(params?: {
    context?: HttpContext
    body?: AppleViewModel
  }
): Observable<LoginResultModelResponseModel> {

    return this.identityAccountAppleLoginPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LoginResultModelResponseModel>) => r.body as LoginResultModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAppleLoginPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAppleLoginPost$Json$Response(params?: {
    context?: HttpContext
    body?: AppleViewModel
  }
): Observable<StrictHttpResponse<LoginResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppleService.IdentityAccountAppleLoginPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAppleLoginPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAppleLoginPost$Json(params?: {
    context?: HttpContext
    body?: AppleViewModel
  }
): Observable<LoginResultModelResponseModel> {

    return this.identityAccountAppleLoginPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LoginResultModelResponseModel>) => r.body as LoginResultModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountAppleLinkAccountIdPost
   */
  static readonly IdentityAccountAppleLinkAccountIdPostPath = '/identity/Account/Apple/Link/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAppleLinkAccountIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAppleLinkAccountIdPost$Plain$Response(params: {
    accountId: string;
    context?: HttpContext
    body?: AppleViewModel
  }
): Observable<StrictHttpResponse<ApplicationUserResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppleService.IdentityAccountAppleLinkAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAppleLinkAccountIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAppleLinkAccountIdPost$Plain(params: {
    accountId: string;
    context?: HttpContext
    body?: AppleViewModel
  }
): Observable<ApplicationUserResponseModel> {

    return this.identityAccountAppleLinkAccountIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserResponseModel>) => r.body as ApplicationUserResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAppleLinkAccountIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAppleLinkAccountIdPost$Json$Response(params: {
    accountId: string;
    context?: HttpContext
    body?: AppleViewModel
  }
): Observable<StrictHttpResponse<ApplicationUserResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppleService.IdentityAccountAppleLinkAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAppleLinkAccountIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAppleLinkAccountIdPost$Json(params: {
    accountId: string;
    context?: HttpContext
    body?: AppleViewModel
  }
): Observable<ApplicationUserResponseModel> {

    return this.identityAccountAppleLinkAccountIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserResponseModel>) => r.body as ApplicationUserResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountAppleLinkAccountIdDelete
   */
  static readonly IdentityAccountAppleLinkAccountIdDeletePath = '/identity/Account/Apple/Link/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAppleLinkAccountIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAppleLinkAccountIdDelete$Plain$Response(params: {
    accountId: string;
    context?: HttpContext
    body?: AppleViewModel
  }
): Observable<StrictHttpResponse<ApplicationUserResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppleService.IdentityAccountAppleLinkAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAppleLinkAccountIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAppleLinkAccountIdDelete$Plain(params: {
    accountId: string;
    context?: HttpContext
    body?: AppleViewModel
  }
): Observable<ApplicationUserResponseModel> {

    return this.identityAccountAppleLinkAccountIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserResponseModel>) => r.body as ApplicationUserResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountAppleLinkAccountIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAppleLinkAccountIdDelete$Json$Response(params: {
    accountId: string;
    context?: HttpContext
    body?: AppleViewModel
  }
): Observable<StrictHttpResponse<ApplicationUserResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppleService.IdentityAccountAppleLinkAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountAppleLinkAccountIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountAppleLinkAccountIdDelete$Json(params: {
    accountId: string;
    context?: HttpContext
    body?: AppleViewModel
  }
): Observable<ApplicationUserResponseModel> {

    return this.identityAccountAppleLinkAccountIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserResponseModel>) => r.body as ApplicationUserResponseModel)
    );
  }

}
