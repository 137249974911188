/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Account } from '../models/account';
import { AccountInputModel } from '../models/account-input-model';
import { AccountListResponseModel } from '../models/account-list-response-model';
import { AccountPaginationModelResponseModel } from '../models/account-pagination-model-response-model';
import { AccountResponseModel } from '../models/account-response-model';
import { Int32ResponseModel } from '../models/int-32-response-model';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation emailAccountReloadGet
   */
  static readonly EmailAccountReloadGetPath = '/email/Account/Reload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailAccountReloadGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAccountReloadGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Int32ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.EmailAccountReloadGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int32ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailAccountReloadGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAccountReloadGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<Int32ResponseModel> {

    return this.emailAccountReloadGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ResponseModel>) => r.body as Int32ResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailAccountReloadGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAccountReloadGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Int32ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.EmailAccountReloadGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int32ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailAccountReloadGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAccountReloadGet$Json(params?: {
    context?: HttpContext
  }
): Observable<Int32ResponseModel> {

    return this.emailAccountReloadGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ResponseModel>) => r.body as Int32ResponseModel)
    );
  }

  /**
   * Path part for operation emailAccountCountGet
   */
  static readonly EmailAccountCountGetPath = '/email/Account/Count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailAccountCountGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAccountCountGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Int32ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.EmailAccountCountGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int32ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailAccountCountGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAccountCountGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<Int32ResponseModel> {

    return this.emailAccountCountGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ResponseModel>) => r.body as Int32ResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailAccountCountGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAccountCountGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Int32ResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.EmailAccountCountGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int32ResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailAccountCountGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAccountCountGet$Json(params?: {
    context?: HttpContext
  }
): Observable<Int32ResponseModel> {

    return this.emailAccountCountGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Int32ResponseModel>) => r.body as Int32ResponseModel)
    );
  }

  /**
   * Path part for operation emailAccountPagePageLimitGet
   */
  static readonly EmailAccountPagePageLimitGetPath = '/email/Account/Page/{page}/{limit}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailAccountPagePageLimitGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAccountPagePageLimitGet$Plain$Response(params: {
    page: number;
    limit: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AccountPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.EmailAccountPagePageLimitGetPath, 'get');
    if (params) {
      rb.path('page', params.page, {});
      rb.path('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailAccountPagePageLimitGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAccountPagePageLimitGet$Plain(params: {
    page: number;
    limit: number;
    context?: HttpContext
  }
): Observable<AccountPaginationModelResponseModel> {

    return this.emailAccountPagePageLimitGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountPaginationModelResponseModel>) => r.body as AccountPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailAccountPagePageLimitGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAccountPagePageLimitGet$Json$Response(params: {
    page: number;
    limit: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AccountPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.EmailAccountPagePageLimitGetPath, 'get');
    if (params) {
      rb.path('page', params.page, {});
      rb.path('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailAccountPagePageLimitGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAccountPagePageLimitGet$Json(params: {
    page: number;
    limit: number;
    context?: HttpContext
  }
): Observable<AccountPaginationModelResponseModel> {

    return this.emailAccountPagePageLimitGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountPaginationModelResponseModel>) => r.body as AccountPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation emailAccountIdGet
   */
  static readonly EmailAccountIdGetPath = '/email/account/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailAccountIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAccountIdGet$Plain$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.EmailAccountIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailAccountIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAccountIdGet$Plain(params: {
    id: string;
    context?: HttpContext
  }
): Observable<AccountResponseModel> {

    return this.emailAccountIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailAccountIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAccountIdGet$Json$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.EmailAccountIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailAccountIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAccountIdGet$Json(params: {
    id: string;
    context?: HttpContext
  }
): Observable<AccountResponseModel> {

    return this.emailAccountIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * Path part for operation emailAccountAppidAppIdAccountNameAccountNameGet
   */
  static readonly EmailAccountAppidAppIdAccountNameAccountNameGetPath = '/email/account/appid/{appId}/accountName/{accountName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailAccountAppidAppIdAccountNameAccountNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAccountAppidAppIdAccountNameAccountNameGet$Plain$Response(params: {
    appId: string;
    accountName: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.EmailAccountAppidAppIdAccountNameAccountNameGetPath, 'get');
    if (params) {
      rb.path('appId', params.appId, {});
      rb.path('accountName', params.accountName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailAccountAppidAppIdAccountNameAccountNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAccountAppidAppIdAccountNameAccountNameGet$Plain(params: {
    appId: string;
    accountName: string;
    context?: HttpContext
  }
): Observable<AccountResponseModel> {

    return this.emailAccountAppidAppIdAccountNameAccountNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailAccountAppidAppIdAccountNameAccountNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAccountAppidAppIdAccountNameAccountNameGet$Json$Response(params: {
    appId: string;
    accountName: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.EmailAccountAppidAppIdAccountNameAccountNameGetPath, 'get');
    if (params) {
      rb.path('appId', params.appId, {});
      rb.path('accountName', params.accountName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailAccountAppidAppIdAccountNameAccountNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailAccountAppidAppIdAccountNameAccountNameGet$Json(params: {
    appId: string;
    accountName: string;
    context?: HttpContext
  }
): Observable<AccountResponseModel> {

    return this.emailAccountAppidAppIdAccountNameAccountNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * Path part for operation emailAccountCreatePost
   */
  static readonly EmailAccountCreatePostPath = '/email/Account/Create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailAccountCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  emailAccountCreatePost$Plain$Response(params?: {
    context?: HttpContext
    body?: Array<AccountInputModel>
  }
): Observable<StrictHttpResponse<AccountListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.EmailAccountCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailAccountCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  emailAccountCreatePost$Plain(params?: {
    context?: HttpContext
    body?: Array<AccountInputModel>
  }
): Observable<AccountListResponseModel> {

    return this.emailAccountCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountListResponseModel>) => r.body as AccountListResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailAccountCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  emailAccountCreatePost$Json$Response(params?: {
    context?: HttpContext
    body?: Array<AccountInputModel>
  }
): Observable<StrictHttpResponse<AccountListResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.EmailAccountCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountListResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailAccountCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  emailAccountCreatePost$Json(params?: {
    context?: HttpContext
    body?: Array<AccountInputModel>
  }
): Observable<AccountListResponseModel> {

    return this.emailAccountCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountListResponseModel>) => r.body as AccountListResponseModel)
    );
  }

  /**
   * Path part for operation emailAccountUpdateIdPut
   */
  static readonly EmailAccountUpdateIdPutPath = '/email/Account/Update/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailAccountUpdateIdPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  emailAccountUpdateIdPut$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: Account
  }
): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.EmailAccountUpdateIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailAccountUpdateIdPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  emailAccountUpdateIdPut$Plain(params: {
    id: string;
    context?: HttpContext
    body?: Account
  }
): Observable<AccountResponseModel> {

    return this.emailAccountUpdateIdPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailAccountUpdateIdPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  emailAccountUpdateIdPut$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: Account
  }
): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.EmailAccountUpdateIdPutPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailAccountUpdateIdPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  emailAccountUpdateIdPut$Json(params: {
    id: string;
    context?: HttpContext
    body?: Account
  }
): Observable<AccountResponseModel> {

    return this.emailAccountUpdateIdPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * Path part for operation emailAccountDeleteIdDelete
   */
  static readonly EmailAccountDeleteIdDeletePath = '/email/Account/Delete/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailAccountDeleteIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  emailAccountDeleteIdDelete$Plain$Response(params: {
    id: string;
    context?: HttpContext
    body?: Account
  }
): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.EmailAccountDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailAccountDeleteIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  emailAccountDeleteIdDelete$Plain(params: {
    id: string;
    context?: HttpContext
    body?: Account
  }
): Observable<AccountResponseModel> {

    return this.emailAccountDeleteIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailAccountDeleteIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  emailAccountDeleteIdDelete$Json$Response(params: {
    id: string;
    context?: HttpContext
    body?: Account
  }
): Observable<StrictHttpResponse<AccountResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.EmailAccountDeleteIdDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `emailAccountDeleteIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  emailAccountDeleteIdDelete$Json(params: {
    id: string;
    context?: HttpContext
    body?: Account
  }
): Observable<AccountResponseModel> {

    return this.emailAccountDeleteIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AccountResponseModel>) => r.body as AccountResponseModel)
    );
  }

}
