/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LoginResultModelResponseModel } from '../models/login-result-model-response-model';
import { StringResponseModel } from '../models/string-response-model';
import { TwitterMeResponseModel } from '../models/twitter-me-response-model';

@Injectable({
  providedIn: 'root',
})
export class TwitterService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation identityAccountTwitterOauthGenerateurlGet
   */
  static readonly IdentityAccountTwitterOauthGenerateurlGetPath = '/identity/Account/twitter/oauth/generateurl';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountTwitterOauthGenerateurlGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountTwitterOauthGenerateurlGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, TwitterService.IdentityAccountTwitterOauthGenerateurlGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountTwitterOauthGenerateurlGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountTwitterOauthGenerateurlGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<StringResponseModel> {

    return this.identityAccountTwitterOauthGenerateurlGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountTwitterOauthGenerateurlGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountTwitterOauthGenerateurlGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, TwitterService.IdentityAccountTwitterOauthGenerateurlGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountTwitterOauthGenerateurlGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountTwitterOauthGenerateurlGet$Json(params?: {
    context?: HttpContext
  }
): Observable<StringResponseModel> {

    return this.identityAccountTwitterOauthGenerateurlGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountTwitterOauthLoginGet
   */
  static readonly IdentityAccountTwitterOauthLoginGetPath = '/identity/account/twitter/oauth/login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountTwitterOauthLoginGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountTwitterOauthLoginGet$Plain$Response(params?: {
    oauth_token?: string;
    oauth_verifier?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<LoginResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, TwitterService.IdentityAccountTwitterOauthLoginGetPath, 'get');
    if (params) {
      rb.query('oauth_token', params.oauth_token, {});
      rb.query('oauth_verifier', params.oauth_verifier, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountTwitterOauthLoginGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountTwitterOauthLoginGet$Plain(params?: {
    oauth_token?: string;
    oauth_verifier?: string;
    context?: HttpContext
  }
): Observable<LoginResultModelResponseModel> {

    return this.identityAccountTwitterOauthLoginGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LoginResultModelResponseModel>) => r.body as LoginResultModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountTwitterOauthLoginGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountTwitterOauthLoginGet$Json$Response(params?: {
    oauth_token?: string;
    oauth_verifier?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<LoginResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, TwitterService.IdentityAccountTwitterOauthLoginGetPath, 'get');
    if (params) {
      rb.query('oauth_token', params.oauth_token, {});
      rb.query('oauth_verifier', params.oauth_verifier, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountTwitterOauthLoginGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountTwitterOauthLoginGet$Json(params?: {
    oauth_token?: string;
    oauth_verifier?: string;
    context?: HttpContext
  }
): Observable<LoginResultModelResponseModel> {

    return this.identityAccountTwitterOauthLoginGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LoginResultModelResponseModel>) => r.body as LoginResultModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountTwitterOauth2LoginGet
   */
  static readonly IdentityAccountTwitterOauth2LoginGetPath = '/identity/account/twitter/oauth2/login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountTwitterOauth2LoginGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountTwitterOauth2LoginGet$Plain$Response(params?: {
    state?: string;
    code?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<TwitterMeResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, TwitterService.IdentityAccountTwitterOauth2LoginGetPath, 'get');
    if (params) {
      rb.query('state', params.state, {});
      rb.query('code', params.code, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TwitterMeResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountTwitterOauth2LoginGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountTwitterOauth2LoginGet$Plain(params?: {
    state?: string;
    code?: string;
    context?: HttpContext
  }
): Observable<TwitterMeResponseModel> {

    return this.identityAccountTwitterOauth2LoginGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TwitterMeResponseModel>) => r.body as TwitterMeResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountTwitterOauth2LoginGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountTwitterOauth2LoginGet$Json$Response(params?: {
    state?: string;
    code?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<TwitterMeResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, TwitterService.IdentityAccountTwitterOauth2LoginGetPath, 'get');
    if (params) {
      rb.query('state', params.state, {});
      rb.query('code', params.code, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TwitterMeResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountTwitterOauth2LoginGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityAccountTwitterOauth2LoginGet$Json(params?: {
    state?: string;
    code?: string;
    context?: HttpContext
  }
): Observable<TwitterMeResponseModel> {

    return this.identityAccountTwitterOauth2LoginGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TwitterMeResponseModel>) => r.body as TwitterMeResponseModel)
    );
  }

}
