import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private static readonly _subject = new ReplaySubject<boolean>();
  private static _loading: boolean = false;

  public static $(value: boolean) {
    this._subject.next(value);
  }

  public static $$(): Observable<boolean> {
    return this._subject.pipe(tap((res) => this._loading = res));
  }

  public static IsLoading(): boolean {
    return this._loading;
  }
}
