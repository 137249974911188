/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApplicationUserResponseModel } from '../models/application-user-response-model';
import { FacebookViewModel } from '../models/facebook-view-model';
import { LoginResultModelResponseModel } from '../models/login-result-model-response-model';
import { StringResponseModel } from '../models/string-response-model';

@Injectable({
  providedIn: 'root',
})
export class FacebookService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation identityAccountFacebookPreRegisterPost
   */
  static readonly IdentityAccountFacebookPreRegisterPostPath = '/identity/Account/Facebook/PreRegister';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountFacebookPreRegisterPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountFacebookPreRegisterPost$Plain$Response(params?: {
    context?: HttpContext
    body?: FacebookViewModel
  }
): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FacebookService.IdentityAccountFacebookPreRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountFacebookPreRegisterPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountFacebookPreRegisterPost$Plain(params?: {
    context?: HttpContext
    body?: FacebookViewModel
  }
): Observable<StringResponseModel> {

    return this.identityAccountFacebookPreRegisterPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountFacebookPreRegisterPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountFacebookPreRegisterPost$Json$Response(params?: {
    context?: HttpContext
    body?: FacebookViewModel
  }
): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FacebookService.IdentityAccountFacebookPreRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountFacebookPreRegisterPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountFacebookPreRegisterPost$Json(params?: {
    context?: HttpContext
    body?: FacebookViewModel
  }
): Observable<StringResponseModel> {

    return this.identityAccountFacebookPreRegisterPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountFacebookRegisterPost
   */
  static readonly IdentityAccountFacebookRegisterPostPath = '/identity/Account/Facebook/Register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountFacebookRegisterPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountFacebookRegisterPost$Plain$Response(params?: {
    context?: HttpContext
    body?: FacebookViewModel
  }
): Observable<StrictHttpResponse<LoginResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FacebookService.IdentityAccountFacebookRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountFacebookRegisterPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountFacebookRegisterPost$Plain(params?: {
    context?: HttpContext
    body?: FacebookViewModel
  }
): Observable<LoginResultModelResponseModel> {

    return this.identityAccountFacebookRegisterPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LoginResultModelResponseModel>) => r.body as LoginResultModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountFacebookRegisterPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountFacebookRegisterPost$Json$Response(params?: {
    context?: HttpContext
    body?: FacebookViewModel
  }
): Observable<StrictHttpResponse<LoginResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FacebookService.IdentityAccountFacebookRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountFacebookRegisterPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountFacebookRegisterPost$Json(params?: {
    context?: HttpContext
    body?: FacebookViewModel
  }
): Observable<LoginResultModelResponseModel> {

    return this.identityAccountFacebookRegisterPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LoginResultModelResponseModel>) => r.body as LoginResultModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountFacebookLoginPost
   */
  static readonly IdentityAccountFacebookLoginPostPath = '/identity/Account/Facebook/Login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountFacebookLoginPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountFacebookLoginPost$Plain$Response(params?: {
    context?: HttpContext
    body?: FacebookViewModel
  }
): Observable<StrictHttpResponse<LoginResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FacebookService.IdentityAccountFacebookLoginPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountFacebookLoginPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountFacebookLoginPost$Plain(params?: {
    context?: HttpContext
    body?: FacebookViewModel
  }
): Observable<LoginResultModelResponseModel> {

    return this.identityAccountFacebookLoginPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LoginResultModelResponseModel>) => r.body as LoginResultModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountFacebookLoginPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountFacebookLoginPost$Json$Response(params?: {
    context?: HttpContext
    body?: FacebookViewModel
  }
): Observable<StrictHttpResponse<LoginResultModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FacebookService.IdentityAccountFacebookLoginPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginResultModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountFacebookLoginPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountFacebookLoginPost$Json(params?: {
    context?: HttpContext
    body?: FacebookViewModel
  }
): Observable<LoginResultModelResponseModel> {

    return this.identityAccountFacebookLoginPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LoginResultModelResponseModel>) => r.body as LoginResultModelResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountFacebookLinkAccountIdPost
   */
  static readonly IdentityAccountFacebookLinkAccountIdPostPath = '/identity/Account/Facebook/Link/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountFacebookLinkAccountIdPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountFacebookLinkAccountIdPost$Plain$Response(params: {
    accountId: string;
    context?: HttpContext
    body?: FacebookViewModel
  }
): Observable<StrictHttpResponse<ApplicationUserResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FacebookService.IdentityAccountFacebookLinkAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountFacebookLinkAccountIdPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountFacebookLinkAccountIdPost$Plain(params: {
    accountId: string;
    context?: HttpContext
    body?: FacebookViewModel
  }
): Observable<ApplicationUserResponseModel> {

    return this.identityAccountFacebookLinkAccountIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserResponseModel>) => r.body as ApplicationUserResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountFacebookLinkAccountIdPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountFacebookLinkAccountIdPost$Json$Response(params: {
    accountId: string;
    context?: HttpContext
    body?: FacebookViewModel
  }
): Observable<StrictHttpResponse<ApplicationUserResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FacebookService.IdentityAccountFacebookLinkAccountIdPostPath, 'post');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountFacebookLinkAccountIdPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountFacebookLinkAccountIdPost$Json(params: {
    accountId: string;
    context?: HttpContext
    body?: FacebookViewModel
  }
): Observable<ApplicationUserResponseModel> {

    return this.identityAccountFacebookLinkAccountIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserResponseModel>) => r.body as ApplicationUserResponseModel)
    );
  }

  /**
   * Path part for operation identityAccountFacebookLinkAccountIdDelete
   */
  static readonly IdentityAccountFacebookLinkAccountIdDeletePath = '/identity/Account/Facebook/Link/{accountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountFacebookLinkAccountIdDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountFacebookLinkAccountIdDelete$Plain$Response(params: {
    accountId: string;
    context?: HttpContext
    body?: FacebookViewModel
  }
): Observable<StrictHttpResponse<ApplicationUserResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FacebookService.IdentityAccountFacebookLinkAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountFacebookLinkAccountIdDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountFacebookLinkAccountIdDelete$Plain(params: {
    accountId: string;
    context?: HttpContext
    body?: FacebookViewModel
  }
): Observable<ApplicationUserResponseModel> {

    return this.identityAccountFacebookLinkAccountIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserResponseModel>) => r.body as ApplicationUserResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityAccountFacebookLinkAccountIdDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountFacebookLinkAccountIdDelete$Json$Response(params: {
    accountId: string;
    context?: HttpContext
    body?: FacebookViewModel
  }
): Observable<StrictHttpResponse<ApplicationUserResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, FacebookService.IdentityAccountFacebookLinkAccountIdDeletePath, 'delete');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationUserResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityAccountFacebookLinkAccountIdDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  identityAccountFacebookLinkAccountIdDelete$Json(params: {
    accountId: string;
    context?: HttpContext
    body?: FacebookViewModel
  }
): Observable<ApplicationUserResponseModel> {

    return this.identityAccountFacebookLinkAccountIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ApplicationUserResponseModel>) => r.body as ApplicationUserResponseModel)
    );
  }

}
