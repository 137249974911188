import { HttpContextToken } from "@angular/common/http";

export enum CRYPTO {
  TOKEN_KEY = "DVROrKiHmncUgWBj",
  TOKEN_IV = "xcsJYPvUkxjdLi8b",
}

export enum STORAGE_KEY {
  TOKEN = "token",
  TYPE_LOGIN = "type_login",
  LANGUAGE = "language",
  ACCOUNTS = "accounts",
  ADDRESS = "address",
  ID_ACCOUNT_LOGINED = "id_account_logined"
}

export enum PLACE_TYPE {
  OTHER = "OTHER",
  BILLING = "BILLING",
  DELIVERY = "DELIVERY"
}

export enum PLACE_LABEL {
  OTHER = "OTHER",
  PERSONAL = "PERSONAL",
  WORK = "WORK",
  HOME = "HOME"
}

export const TOKEN = new HttpContextToken<string | null>(() => null);

export interface AddressResultModel {
  addressFull: string;
  address: string;
  addressDetails?: any;
  street: string;
  streetNumber: string;
  streetAddress?: any;
  ward?: any;
  district?: any;
  zipCode: string;
  city: string;
  department?: any;
  country: string;
  countryCode: string;
  lat: number;
  lng: number;
  gmUrl: string;
  gmPlaceId: string;
  administrativeArea1: string;
  administrativeArea2: string;
  administrativeArea3: string;
  administrativeArea4?: any;
  administrativeArea5?: any;
  administrativeArea6?: any;
  administrativeArea7?: any;
}

export interface IAccountStorage {
  email: string;
  token: string;
  accountId: string;
  typeLogin: string;
  avatar?: string;
}

export interface IReponseBooleanModel {
  res: number;
  error: string;
  errorCode: string;
  accessToken: string;
  data: boolean;
}

export enum CALLBACK_TYPE {
  IN = "in", // handle error in identity
  OUT = "out", // handle error in app
}

export enum TYPE_LOGIN {
  FACEBOOK = "facebook",
  GOOGLE = "google",
  NORMAL = "normal",
}

export enum GENDER {
  MALE = "GENDER_MALE",
  FEMALE = "GENDER_FEMALE",
  UNDEFINED = "GENDER_UNDEFINED"
}

export enum USER_ACTION {
  LOGIN = "login",
  CHANGE_PASSWORD = "changepassword",
  LOGIN_FB = "login_facebook",
  LOGIN_GG = "login_google",
  LOGIN_APPLE = "login_apple",
  REGISTER = "register",
  ACTIVE = "active",
}

export enum FLUTTER_MEMTHOD {
  IDENTITY = "identity", //login && register
  LOGOUT = "logout",
  CHANGE_PASSWORD = "changepassword",
  ACTIVE = "active",
}

export enum SMTP_CONFIG {
  APP_ID = "inlivegroup",
  SUBJECT = "Account Activation Email",
  NAME = "noreply@inlive-group.com",
}

export const APPLE_INIT = {
  clientId: 'com.inlivegroup.client',
  scope: 'name email',
  redirectURI: `${window.origin}/social`,
  state: 'request',
  nonce: 'login',
  usePopup: true //or false defaults to false
} as const;

export function parseJwt(token: string) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export function blobToFile(theBlob: Blob, fileName: string): File {
  const b: any = theBlob;
  b.lastModifiedDate = new Date();
  b.name = fileName;
  return theBlob as File;
}

export const NICKNAME_CELEBRITY_REGEX = /^[a-zA-Z0-9àâäæáãåāèéêëęėēîïīįíìôōøõóòöœùûüūúÿçćčńñÀÂÄÆÁÃÅĀÈÉÊËĘĖĒÎÏĪĮÍÌÔŌØÕÓÒÖŒÙÛÜŪÚŸÇĆČŃÑ\!\?\'\.\#\*\€\$\/\%\&\(\)\+\-\_\=\>\<\s]{2,}$/;
export const NICKNAME_REGEX = /^[a-zA-Z0-9_]{0,}$/;
export const EMAIL_REGEX =
  /^[a-zA-Z0-9.\-_]+@(?=[a-zA-Z0-9\-_])(?=[a-zA-Z0-9.\-_])[a-zA-Z0-9.\-_]{1,}(?=[a-zA-Z0-9\-_]).\.(?=[a-zA-Z0-9])[a-zA-Z0-9]{2,}$/;
export const PWD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$&\-*~])(?=.*[0-9])(.*).{6,}$/;
export const LINK_REGEX =
  /^(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?$/;

export const UPLOAD_URL = 'https://stag.upload.ilwith.io'
