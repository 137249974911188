<div class="pop-up">
    <div class="icon">
        <img src="./assets/images/alert-triangle.svg" alt="">
    </div>
    <div class="title">
        Compte non vérifié
    </div>
    <div class="content">
        <p>
            Votre compte n’a pas encore été vérifié. Accédez à votre boîte mail et confirmez votre inscription.
        </p>
        <p>
            Vous n’avez pas reçu le lien de vérification, <span class="link" (click)="goto()">cliquez ici</span>.
        </p>
    </div>
    <div class="footer">
        <button (click)="close()">Annuler</button>
    </div>
</div>