import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full', },
  { path: 'version', loadChildren: () => import('./pages/version/version.module').then((m) => m.VersionModule), },
  { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule), },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule), },
  { path: 'manage-account', loadChildren: () => import('./pages/manage-account/manage-account.module').then((m) => m.ManageAccountModule), },
  { path: 'profile/:email', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule) },
  { path: 'map', loadChildren: () => import('./pages/map/map.module').then(m => m.MapModule) },
  { path: 'delete-account/:email', loadChildren: () => import('./pages/delete-account/delete-account.module').then(m => m.DeleteAccountModule) },
  { path: 'google-authenticator', loadChildren: () => import('./pages/google-authenticator/google-authenticator.module').then(m => m.GoogleAuthenticatorModule) },
  { path: 'profile-bo/:email', loadChildren: () => import('./pages/profile-bo/profile-bo.module').then(m => m.ProfileBoModule) },
  { path: '**', redirectTo: '', pathMatch: 'full', },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
