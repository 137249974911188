/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanResponseModel } from '../models/boolean-response-model';
import { StringResponseModel } from '../models/string-response-model';
import { TwoFactorAuthenticationModelResponseModel } from '../models/two-factor-authentication-model-response-model';

@Injectable({
  providedIn: 'root',
})
export class TwoFactorAuthenticationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation identityFaInitializeEmailPost
   */
  static readonly IdentityFaInitializeEmailPostPath = '/identity/fa/initialize/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityFaInitializeEmailPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityFaInitializeEmailPost$Plain$Response(params: {
    email: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, TwoFactorAuthenticationService.IdentityFaInitializeEmailPostPath, 'post');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityFaInitializeEmailPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityFaInitializeEmailPost$Plain(params: {
    email: string;
    context?: HttpContext
  }
): Observable<StringResponseModel> {

    return this.identityFaInitializeEmailPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityFaInitializeEmailPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityFaInitializeEmailPost$Json$Response(params: {
    email: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<StringResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, TwoFactorAuthenticationService.IdentityFaInitializeEmailPostPath, 'post');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StringResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityFaInitializeEmailPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityFaInitializeEmailPost$Json(params: {
    email: string;
    context?: HttpContext
  }
): Observable<StringResponseModel> {

    return this.identityFaInitializeEmailPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StringResponseModel>) => r.body as StringResponseModel)
    );
  }

  /**
   * Path part for operation identityFaInitializePost
   */
  static readonly IdentityFaInitializePostPath = '/identity/fa/initialize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityFaInitializePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityFaInitializePost$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<TwoFactorAuthenticationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, TwoFactorAuthenticationService.IdentityFaInitializePostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TwoFactorAuthenticationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityFaInitializePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityFaInitializePost$Plain(params?: {
    context?: HttpContext
  }
): Observable<TwoFactorAuthenticationModelResponseModel> {

    return this.identityFaInitializePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TwoFactorAuthenticationModelResponseModel>) => r.body as TwoFactorAuthenticationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityFaInitializePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityFaInitializePost$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<TwoFactorAuthenticationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, TwoFactorAuthenticationService.IdentityFaInitializePostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TwoFactorAuthenticationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityFaInitializePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityFaInitializePost$Json(params?: {
    context?: HttpContext
  }
): Observable<TwoFactorAuthenticationModelResponseModel> {

    return this.identityFaInitializePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TwoFactorAuthenticationModelResponseModel>) => r.body as TwoFactorAuthenticationModelResponseModel)
    );
  }

  /**
   * Path part for operation identityFaAddAuthenticatorPost
   */
  static readonly IdentityFaAddAuthenticatorPostPath = '/identity/fa/add/authenticator';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityFaAddAuthenticatorPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityFaAddAuthenticatorPost$Plain$Response(params?: {
    code?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<TwoFactorAuthenticationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, TwoFactorAuthenticationService.IdentityFaAddAuthenticatorPostPath, 'post');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TwoFactorAuthenticationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityFaAddAuthenticatorPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityFaAddAuthenticatorPost$Plain(params?: {
    code?: string;
    context?: HttpContext
  }
): Observable<TwoFactorAuthenticationModelResponseModel> {

    return this.identityFaAddAuthenticatorPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TwoFactorAuthenticationModelResponseModel>) => r.body as TwoFactorAuthenticationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityFaAddAuthenticatorPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityFaAddAuthenticatorPost$Json$Response(params?: {
    code?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<TwoFactorAuthenticationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, TwoFactorAuthenticationService.IdentityFaAddAuthenticatorPostPath, 'post');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TwoFactorAuthenticationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityFaAddAuthenticatorPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityFaAddAuthenticatorPost$Json(params?: {
    code?: string;
    context?: HttpContext
  }
): Observable<TwoFactorAuthenticationModelResponseModel> {

    return this.identityFaAddAuthenticatorPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TwoFactorAuthenticationModelResponseModel>) => r.body as TwoFactorAuthenticationModelResponseModel)
    );
  }

  /**
   * Path part for operation identityFaDisablePost
   */
  static readonly IdentityFaDisablePostPath = '/identity/fa/disable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityFaDisablePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityFaDisablePost$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TwoFactorAuthenticationService.IdentityFaDisablePostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityFaDisablePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityFaDisablePost(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.identityFaDisablePost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation identityFaVerifyPost
   */
  static readonly IdentityFaVerifyPostPath = '/identity/fa/verify';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityFaVerifyPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityFaVerifyPost$Plain$Response(params?: {
    code?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, TwoFactorAuthenticationService.IdentityFaVerifyPostPath, 'post');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityFaVerifyPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityFaVerifyPost$Plain(params?: {
    code?: string;
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.identityFaVerifyPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identityFaVerifyPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityFaVerifyPost$Json$Response(params?: {
    code?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<BooleanResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, TwoFactorAuthenticationService.IdentityFaVerifyPostPath, 'post');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `identityFaVerifyPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identityFaVerifyPost$Json(params?: {
    code?: string;
    context?: HttpContext
  }
): Observable<BooleanResponseModel> {

    return this.identityFaVerifyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanResponseModel>) => r.body as BooleanResponseModel)
    );
  }

}
