import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as sha256 from 'crypto-js/sha256';
import * as hmacSHA512 from 'crypto-js/hmac-sha512';
import * as Base64 from 'crypto-js/enc-base64';
import { CRYPTO } from 'src/app/core/const';


@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  private static _key: any = CryptoJS.enc.Utf8.parse(CRYPTO.TOKEN_KEY);
  private static _iv: any = CryptoJS.enc.Utf8.parse(CRYPTO.TOKEN_IV);

  public static EncryptUsingSha256(req: string): string {
    const hashDigest = sha256(CRYPTO.TOKEN_IV + req);
    let result = Base64.stringify(hmacSHA512(CRYPTO.TOKEN_IV + hashDigest, CRYPTO.TOKEN_KEY));
    return result;
  }

  public static EncryptUsingAES256(req: string): string {

    let result = CryptoJS.AES.encrypt(
      JSON.stringify(req), this._key, {
      keySize: 16,
      iv: this._iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString();

    return result;
  }

  public static DecryptUsingAES256(encrypted: string): string {

    let result = CryptoJS.AES.decrypt(
      encrypted, this._key, {
      keySize: 16,
      iv: this._iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    return result == '' ? '' : JSON.parse(result);
  }
}
