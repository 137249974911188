import { Injectable } from '@angular/core';
import { CryptoService } from './crypto.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageCustomService {

  public static Add(key: string, value: string) {
    key = CryptoService.EncryptUsingSha256(key);
    value = CryptoService.EncryptUsingAES256(value);
    localStorage.setItem(key, value);
  }

  public static Get(key: string) {
    key = CryptoService.EncryptUsingSha256(key);
    return CryptoService.DecryptUsingAES256(localStorage.getItem(key) || '');
  }

  public static Remove(key: string) {
    key = CryptoService.EncryptUsingSha256(key);
    localStorage.removeItem(key);
  }

  public static Clear() {
    localStorage.clear();
  }
}
