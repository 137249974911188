import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PopUpService } from 'src/app/core/services/pop-up.service';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss'],
})
export class PopUpComponent implements AfterViewInit, OnDestroy {

  showSubscription!: Subscription;
  params: any;

  constructor(private _elmRef: ElementRef, private _changeDetectorRef: ChangeDetectorRef, private router: Router, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.params = params;
    })
  }

  ngAfterViewInit(): void {
    this._elmRef.nativeElement.style.display = 'none';
    this.showSubscription = PopUpService.$$().pipe()
      .subscribe((status: boolean) => {
        this._elmRef.nativeElement.style.display = status ? 'flex' : 'none';
        this._changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.showSubscription.unsubscribe();
  }

  close() {
    PopUpService.$(false);
  }

  goto() {
    this.close();
    this.router.navigate(['auth', 'resend-email'], { queryParams: this.params });
  }
}
