import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PopUpService {

    private static readonly _subject = new ReplaySubject<boolean>();
    private static _show: boolean = false;

    public static $(value: boolean) {
        this._subject.next(value);
    }

    public static $$(): Observable<boolean> {
        return this._subject.pipe(tap((res) => this._show = res));
    }

    public static IsLoading(): boolean {
        return this._show;
    }
}
