/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddressLabelEnumPaginationModelResponseModel } from '../models/address-label-enum-pagination-model-response-model';
import { AddressLabelEnumResponseModel } from '../models/address-label-enum-response-model';
import { AddressTypeEnumPaginationModelResponseModel } from '../models/address-type-enum-pagination-model-response-model';
import { AddressTypeEnumResponseModel } from '../models/address-type-enum-response-model';

@Injectable({
  providedIn: 'root',
})
export class AppEnumService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation addressAppenumTypePagePageLimitGet
   */
  static readonly AddressAppenumTypePagePageLimitGetPath = '/address/appenum/type/page/{page}/{limit}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressAppenumTypePagePageLimitGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumTypePagePageLimitGet$Plain$Response(params: {
    page: number;
    limit: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AddressTypeEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.AddressAppenumTypePagePageLimitGetPath, 'get');
    if (params) {
      rb.path('page', params.page, {});
      rb.path('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddressTypeEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressAppenumTypePagePageLimitGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumTypePagePageLimitGet$Plain(params: {
    page: number;
    limit: number;
    context?: HttpContext
  }
): Observable<AddressTypeEnumPaginationModelResponseModel> {

    return this.addressAppenumTypePagePageLimitGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AddressTypeEnumPaginationModelResponseModel>) => r.body as AddressTypeEnumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressAppenumTypePagePageLimitGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumTypePagePageLimitGet$Json$Response(params: {
    page: number;
    limit: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AddressTypeEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.AddressAppenumTypePagePageLimitGetPath, 'get');
    if (params) {
      rb.path('page', params.page, {});
      rb.path('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddressTypeEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressAppenumTypePagePageLimitGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumTypePagePageLimitGet$Json(params: {
    page: number;
    limit: number;
    context?: HttpContext
  }
): Observable<AddressTypeEnumPaginationModelResponseModel> {

    return this.addressAppenumTypePagePageLimitGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AddressTypeEnumPaginationModelResponseModel>) => r.body as AddressTypeEnumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation addressAppenumTypeNameNameGet
   */
  static readonly AddressAppenumTypeNameNameGetPath = '/address/appenum/type/name/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressAppenumTypeNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumTypeNameNameGet$Plain$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AddressTypeEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.AddressAppenumTypeNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddressTypeEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressAppenumTypeNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumTypeNameNameGet$Plain(params: {
    name: string;
    context?: HttpContext
  }
): Observable<AddressTypeEnumResponseModel> {

    return this.addressAppenumTypeNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AddressTypeEnumResponseModel>) => r.body as AddressTypeEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressAppenumTypeNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumTypeNameNameGet$Json$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AddressTypeEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.AddressAppenumTypeNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddressTypeEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressAppenumTypeNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumTypeNameNameGet$Json(params: {
    name: string;
    context?: HttpContext
  }
): Observable<AddressTypeEnumResponseModel> {

    return this.addressAppenumTypeNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AddressTypeEnumResponseModel>) => r.body as AddressTypeEnumResponseModel)
    );
  }

  /**
   * Path part for operation addressAppenumTypeValueValueGet
   */
  static readonly AddressAppenumTypeValueValueGetPath = '/address/appenum/type/value/{value}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressAppenumTypeValueValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumTypeValueValueGet$Plain$Response(params: {
    value: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AddressTypeEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.AddressAppenumTypeValueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddressTypeEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressAppenumTypeValueValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumTypeValueValueGet$Plain(params: {
    value: string;
    context?: HttpContext
  }
): Observable<AddressTypeEnumResponseModel> {

    return this.addressAppenumTypeValueValueGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AddressTypeEnumResponseModel>) => r.body as AddressTypeEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressAppenumTypeValueValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumTypeValueValueGet$Json$Response(params: {
    value: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AddressTypeEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.AddressAppenumTypeValueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddressTypeEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressAppenumTypeValueValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumTypeValueValueGet$Json(params: {
    value: string;
    context?: HttpContext
  }
): Observable<AddressTypeEnumResponseModel> {

    return this.addressAppenumTypeValueValueGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AddressTypeEnumResponseModel>) => r.body as AddressTypeEnumResponseModel)
    );
  }

  /**
   * Path part for operation addressAppenumLabelPagePageLimitGet
   */
  static readonly AddressAppenumLabelPagePageLimitGetPath = '/address/appenum/label/page/{page}/{limit}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressAppenumLabelPagePageLimitGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumLabelPagePageLimitGet$Plain$Response(params: {
    page: number;
    limit: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AddressLabelEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.AddressAppenumLabelPagePageLimitGetPath, 'get');
    if (params) {
      rb.path('page', params.page, {});
      rb.path('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddressLabelEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressAppenumLabelPagePageLimitGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumLabelPagePageLimitGet$Plain(params: {
    page: number;
    limit: number;
    context?: HttpContext
  }
): Observable<AddressLabelEnumPaginationModelResponseModel> {

    return this.addressAppenumLabelPagePageLimitGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AddressLabelEnumPaginationModelResponseModel>) => r.body as AddressLabelEnumPaginationModelResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressAppenumLabelPagePageLimitGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumLabelPagePageLimitGet$Json$Response(params: {
    page: number;
    limit: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AddressLabelEnumPaginationModelResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.AddressAppenumLabelPagePageLimitGetPath, 'get');
    if (params) {
      rb.path('page', params.page, {});
      rb.path('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddressLabelEnumPaginationModelResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressAppenumLabelPagePageLimitGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumLabelPagePageLimitGet$Json(params: {
    page: number;
    limit: number;
    context?: HttpContext
  }
): Observable<AddressLabelEnumPaginationModelResponseModel> {

    return this.addressAppenumLabelPagePageLimitGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AddressLabelEnumPaginationModelResponseModel>) => r.body as AddressLabelEnumPaginationModelResponseModel)
    );
  }

  /**
   * Path part for operation addressAppenumLabelNameNameGet
   */
  static readonly AddressAppenumLabelNameNameGetPath = '/address/appenum/label/name/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressAppenumLabelNameNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumLabelNameNameGet$Plain$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AddressLabelEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.AddressAppenumLabelNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddressLabelEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressAppenumLabelNameNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumLabelNameNameGet$Plain(params: {
    name: string;
    context?: HttpContext
  }
): Observable<AddressLabelEnumResponseModel> {

    return this.addressAppenumLabelNameNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AddressLabelEnumResponseModel>) => r.body as AddressLabelEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressAppenumLabelNameNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumLabelNameNameGet$Json$Response(params: {
    name: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AddressLabelEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.AddressAppenumLabelNameNameGetPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddressLabelEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressAppenumLabelNameNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumLabelNameNameGet$Json(params: {
    name: string;
    context?: HttpContext
  }
): Observable<AddressLabelEnumResponseModel> {

    return this.addressAppenumLabelNameNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AddressLabelEnumResponseModel>) => r.body as AddressLabelEnumResponseModel)
    );
  }

  /**
   * Path part for operation addressAppenumLabelValueValueGet
   */
  static readonly AddressAppenumLabelValueValueGetPath = '/address/appenum/label/value/{value}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressAppenumLabelValueValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumLabelValueValueGet$Plain$Response(params: {
    value: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AddressLabelEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.AddressAppenumLabelValueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddressLabelEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressAppenumLabelValueValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumLabelValueValueGet$Plain(params: {
    value: string;
    context?: HttpContext
  }
): Observable<AddressLabelEnumResponseModel> {

    return this.addressAppenumLabelValueValueGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AddressLabelEnumResponseModel>) => r.body as AddressLabelEnumResponseModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addressAppenumLabelValueValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumLabelValueValueGet$Json$Response(params: {
    value: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AddressLabelEnumResponseModel>> {

    const rb = new RequestBuilder(this.rootUrl, AppEnumService.AddressAppenumLabelValueValueGetPath, 'get');
    if (params) {
      rb.path('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddressLabelEnumResponseModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addressAppenumLabelValueValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addressAppenumLabelValueValueGet$Json(params: {
    value: string;
    context?: HttpContext
  }
): Observable<AddressLabelEnumResponseModel> {

    return this.addressAppenumLabelValueValueGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AddressLabelEnumResponseModel>) => r.body as AddressLabelEnumResponseModel)
    );
  }

}
