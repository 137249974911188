// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from "./environment.interface";

export const environment: IEnvironment = {
  production: false,
  facebookAppId: "462982177790117",
  googleAppId: "47046650388-n540db1seg8jqjk26se9ubcvfrjup1vj.apps.googleusercontent.com",
  facebookAppName: "JWT-dev",
  googleAppName: "inlivewith",
  identityApiUrl: "https://dev.identity.ilwith.io",
  mode: "dev",
  emailApiUrl: "https://email.ilwith.io",
  firebaseApiKey: "AIzaSyAf7ghfgA2Eunq_4yRaEzGwSNn-sgLSWBA",
  storeUrl: 'https://storage.identity.dev.ilwith.io',
  addressWebUrl: 'https://www.address.ilwith.io',
  addressApiUrl: 'https://address.ilwith.io',
  signalrUrl: 'https://signalr.ilwith.io',
  serviceId: 'identity-dev'
} as const;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
